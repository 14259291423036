import React from "react";
import { Accordion, Card, Col, Container, Modal } from "react-bootstrap";
import "./pricing.css";
import "../Home/c4c.css";
import tick from "../../../assets/greencheck.png";
import caret from "../../../assets/caret_down.png";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { API } from "../../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const responsive = {
  tablet: {
    breakpoint: { max: 800, min: 600 },
    items: 1,
    partialVisibilityGutter: 100,
    slidesToSlide: 1,
  },
  smalltablet: {
    breakpoint: { max: 600, min: 400 },
    items: 1,
    partialVisibilityGutter: 70,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
    partialVisibilityGutter: 55,
    slidesToSlide: 1,
  },
};
const PricingSummary = () => {
  const [state, setState] = React.useState({
    pricing: false,
    email: "",
    isLoading: false,
    inDashboard: false,
  });
  const { pricing, email, isLoading, inDashboard } = state;
  const openPricingModal = () => {
    setState({
      ...state,
      pricing: true,
    });
  };
  const closePricingModal = () => {
    setState({
      ...state,
      pricing: false,
    });
  };
  const handleChange = (e) => {
    setState({
      ...state,
      email: e.target.value,
    });
  };
  React.useEffect(() => {
    if(window.location.pathname === "/admin/allsubscriptions") {
      setState({
        ...state,
        inDashboard: true,
      })
    }
  }, [])
  const requestPricing = () => {
    setState({
      ...state,
      isLoading: true,
    })
    const data = {
      email: email,
    };
    axios
      .post(`${API}/pricing-list/`, data)
      .then((response) => {
        console.log("sent");
        notify("Your pricing request was sent successfully!");
        setState({
          ...state,
          pricing: false,
          email: "",
          isLoading: false,
        });
      })
      .catch((error) => {
        notify("Unsuccessful pricing request!");
        setState({
          ...state,
          pricing: false,
          isLoading: false,
        })
      });
  };
  const notify = (message: string) => {
    toast(message, { containerId: "B" });
  };
  return (
    <>
      <Container className={!inDashboard ? "pricing-container" : "pricing-container pricing-container-dash"} fluid={true}>
        {!inDashboard && (<div className="pricing-decription">
          <h3>We have curated payments plans with you in mind</h3>
          <div className="fourthline pymntline"> </div>
        </div>)}
        <div className={!inDashboard ? "pricing-list" : "pricing-list pricing-list-dash" }>
          <div className="pricing-list-heading">
            <span className="pricing-list-ttl">Plans</span>
            <span className="pricing-list-txt">Basic</span>
            <span className="pricing-list-txt">Standard</span>
            <span className="pricing-list-txt">Premium</span>
            <span className="pricing-list-txt">Premium +</span>
          </div>
          <div className="pricing-list-prices">
            <span className="pricing-list-ttl pricing-ttl-color"></span>
            <span className="pricing-list-txt">
              {/* <div className="pricing-list-prices-amt">
                NGN3000<span className="pricing-txt-color">/mo</span>
              </div> */}
              <div className="pricing-txt-color pricing-list-prices-descrip">
                Quick & Easy. This plan provides everything you need to onboard
                your clients or members right away.
              </div>
            </span>
            <span className="pricing-list-txt">
              {/* <div className="pricing-list-prices-amt">
                NGN5000<span className="pricing-txt-color">/mo</span>
              </div> */}
              <div className="pricing-txt-color pricing-list-prices-descrip">
                Easy onboarding and interaction. Start building meaningful
                connections with your clients (individuals and groups).
              </div>
            </span>
            <span className="pricing-list-txt">
              {/* <div className="pricing-list-prices-amt">
                NGN7000<span className="pricing-txt-color">/mo</span>
              </div> */}
              <div className="pricing-txt-color pricing-list-prices-descrip">
                Community management & useful insights on auto-pilot. Gliders
                love this plan.
              </div>
            </span>
            <span className="pricing-list-txt">
              {/* <div className="pricing-list-prices-amt">
                NGN10000<span className="pricing-txt-color">/mo</span>
              </div> */}
              <div className="pricing-txt-color pricing-list-prices-descrip">
                10x the value, 10x the productivity, 10x the results in your
                community.
              </div>
            </span>
          </div>
          <div className="pricing-list-features pricing-ttl-color">
            Features
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Customized Registration page with Payment function enabled
              (Individuals and Organizations)
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Account Creation and Authentication
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Profile Builder
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Personal Development Assessment
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Notice Board (Recommendations Center)
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Appointment Scheduler
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Individual Members Activities and Onboarding Process & Progress
              Tracking
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Administrators Sign in and Dashboard (2 Administrators)
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Payment Tracking (for communities that require members to make
              payments or subscribe)
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Multi Team Member Invites
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Team Members Activities and Onboarding Process & Progress Tracking{" "}
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Internal/External Mentorship/Coaching Appointment Scheduler
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Creation of Labels for Tags (to enable administrators easily
              classify members)
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Administrator-Members Direct messaging/Feedback tool
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Access to Individual Member Reports
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Search/Query Function
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Members Personality and Competency Mix Summary
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Population-level Talents Insights and Competency Ranking
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Individual & Group Development Insights and Recommendation
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Unique Project Application Link Creator
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Application Board/Tracker on Members Dashboard
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Assessment and Program Adaptation personalized to Organizational
              Structure/Needs
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Two Stage Screening Assessment
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Applicants Results and Ranking
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Interview Scheduler and Notification
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              Hiring Assistant (AI)
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-list-features-list">
            <span className="pricing-list-ttl pricing-txt-color">
              API Integration for 3rd Party Applications
            </span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt"></span>
            <span className="pricing-list-txt">
              <img src={tick} alt="stroke" />
            </span>
          </div>
          <div className="pricing-txt-color pricing-nb">
            * Additional charges may be included
          </div>
          <div className="pricing-buttons">
            <span className="pricing-list-ttl pricing-txt-color"></span>
            <span className="pricing-list-txt">
              <button onClick={openPricingModal}>Buy Basic</button>
            </span>
            <span className="pricing-list-txt">
              <button onClick={openPricingModal}>Buy Standard</button>
            </span>
            <span className="pricing-list-txt">
              <button onClick={openPricingModal}>Buy Premium</button>
            </span>
            <span className="pricing-list-txt">
              <button onClick={openPricingModal}>Buy Premium +</button>
            </span>
          </div>
        </div>
        <Carousel
          responsive={responsive}
          containerClass={!inDashboard ? "pricing-carousel" : "pricing-carousel pricing-carousel-dash"}
          partialVisible={true}
          focusOnSelect={true}
          swipeable={true}
          draggable={true}
          keyBoardControl={true}
          transitionDuration={500}
        >
          <div className="mobile-pricing-list">
            <div className={!inDashboard ? "mobile-pricing-list-card" : "mobile-pricing-list-card mobile-pricing-list-card-dash"}>
              <h6 className="mobile-pricing-list-card-ttl">Basic</h6>
              <p className="mobile-pricing-list-card-txt">
                Quick & Easy. This plan provides everything you need to onboard
                your clients or members right away.
              </p>
              <button
                className="mobile-pricing-list-card-btn"
                onClick={openPricingModal}
              >
                Buy Basic
              </button>
            </div>
            <Accordion defaultActiveKey="">
              <Card className={!inDashboard ? "mobile-pricing-list-expand" : "mobile-pricing-list-expand mobile-pricing-list-expand-dash"}>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <div className="mobile-pricing-list-expand-header">
                    <p>WHAT'S INCLUDED</p> <img src={caret} alt="caret" />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div className="mobile-pricing-list-expand-content">
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Customized Registration page with Payment function
                        enabled (Individuals and Organizations)
                      </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Account Creation and Authentication</p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" /> <p>Profile Builder</p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Personal Development Assessment</p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Notice Board (Recommendations Center)</p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Appointment Scheduler </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Individual Members Activities and Onboarding Process &
                        Progress Tracking
                      </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Administrators Sign in and Dashboard (2 Administrators)
                      </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Payment Tracking (for communities that require members
                        to make payments or subscribe)
                      </p>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          <div className="mobile-pricing-list">
            <div className={!inDashboard ? "mobile-pricing-list-card" : "mobile-pricing-list-card mobile-pricing-list-card-dash"}>
              <h6 className="mobile-pricing-list-card-ttl">Standard</h6>
              <p className="mobile-pricing-list-card-txt">
                Easy onboarding and interaction. Start building meaningful
                connections with your clients (individuals and groups).
              </p>
              <button
                className="mobile-pricing-list-card-btn"
                onClick={openPricingModal}
              >
                Buy Standard
              </button>
            </div>
            <Accordion defaultActiveKey="">
              <Card className={!inDashboard ? "mobile-pricing-list-expand" : "mobile-pricing-list-expand mobile-pricing-list-expand-dash"}>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  <div className="mobile-pricing-list-expand-header">
                    <p>WHAT'S INCLUDED</p> <img src={caret} alt="caret" />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <div className="mobile-pricing-list-expand-content">
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Everything under{" "}
                        <span className="under-basic">BASIC</span>
                      </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Multi Team Member Invites</p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Team Members Activities and Onboarding Process &
                        Progress Tracking
                      </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Internal/External Mentorship/Coaching Appointment
                        Scheduler
                      </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Creation of Labels for Tags (to enable administrators
                        easily classify members)
                      </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Administrator-Members Direct messaging/Feedback tool
                      </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Access to Individual Member Reports</p>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          <div className="mobile-pricing-list">
            <div className={!inDashboard ? "mobile-pricing-list-card" : "mobile-pricing-list-card mobile-pricing-list-card-dash"}>
              <h6 className="mobile-pricing-list-card-ttl">Premium</h6>
              <p className="mobile-pricing-list-card-txt">
                Community management & useful insights on auto-pilot. Gliders
                love this plan.
              </p>
              <button
                className="mobile-pricing-list-card-btn"
                onClick={openPricingModal}
              >
                Buy Premium
              </button>
            </div>
            <Accordion defaultActiveKey="">
              <Card className={!inDashboard ? "mobile-pricing-list-expand" : "mobile-pricing-list-expand mobile-pricing-list-expand-dash"}>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  <div className="mobile-pricing-list-expand-header">
                    <p>WHAT'S INCLUDED</p> <img src={caret} alt="caret" />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <div className="mobile-pricing-list-expand-content">
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Everything under{" "}
                        <span className="under-standard">STANDARD</span>
                      </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Search/Query Function</p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Members Personality and Competency Mix Summary</p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Population-level Talents Insights and Competency Ranking
                      </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Individual & Group Development Insights and
                        Recommendation{" "}
                      </p>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          <div className="mobile-pricing-list">
            <div className={!inDashboard ? "mobile-pricing-list-card" : "mobile-pricing-list-card mobile-pricing-list-card-dash"}>
              <h6 className="mobile-pricing-list-card-ttl">Premium +</h6>
              <p className="mobile-pricing-list-card-txt">
                10x the value, 10x the productivity, 10x the results in your
                community.
              </p>
              <button
                className="mobile-pricing-list-card-btn"
                onClick={openPricingModal}
              >
                Buy Premium +
              </button>
            </div>
            <Accordion defaultActiveKey="">
              <Card className={!inDashboard ? "mobile-pricing-list-expand" : "mobile-pricing-list-expand mobile-pricing-list-expand-dash"}>
                <Accordion.Toggle as={Card.Header} eventKey="3">
                  <div className="mobile-pricing-list-expand-header">
                    <p>WHAT'S INCLUDED</p> <img src={caret} alt="caret" />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <div className="mobile-pricing-list-expand-content">
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Everything under{" "}
                        <span className="under-premium">PREMIUM</span>
                      </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Unique Project Application Link Creator</p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Application Board/Tracker on Members Dashboard</p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>
                        Assessment and Program Adaptation personalized to
                        Organizational Structure/Needs
                      </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Two Stage Screening Assessment</p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Applicants Results and Ranking</p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Interview Scheduler and Notification</p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>Hiring Assistant (AI) </p>
                    </div>
                    <div className="mobile-pricing-list-expand-content-list">
                      <img src={tick} alt="stroke" />{" "}
                      <p>API Integration for 3rd Party Applications</p>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </Carousel>
        <ToastContainer
          enableMultiContainer
          containerId={"B"}
          toastClassName="bg-info text-white"
          hideProgressBar={true}
          position={toast.POSITION.TOP_CENTER}
        />
      </Container>
      <Modal show={pricing} onHide={closePricingModal} centered>
        <Modal.Body>
          <h6 className="pricing-modal-ttl">
            Send a request to get the pricing for the various plans
          </h6>
          {/* <label>Enter your email address</label> */}
          <input
            type="email"
            className="pricing-input"
            name="email"
            value={email}
            placeholder="--Enter email address--"
            onChange={handleChange}
          />
          <button className="pricing-modal-btn" onClick={requestPricing}>
            Request for pricing
          </button>
          {isLoading && (<div className="btn-spinner"></div>)}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PricingSummary;
