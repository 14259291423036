import * as React from "react";
import { Link } from "react-router-dom";
import avatar from "../../../assets/avatar.svg";
import Axios from "axios";
import { API } from "../../../config";
import { useEffect, useState } from "react";

export interface IAppProps {
  Logout?: Function | any;
}

export function NavIsLoggedIn(props: IAppProps | any) {
  const [state, setState] = useState({ canseeDashboard: false });
  useEffect(() => {
    getCurrentAssessmentPosition();
  }, []);
  const getCurrentAssessmentPosition = (): void => {
    const availableToken = localStorage.getItem("userToken");
    const token: string = availableToken
      ? JSON.parse(availableToken)
      : "";
    Axios.get(`${API}/erit-progress`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        //console.log(response);
        if (
          response.data[0].payment === true ||
          response.data[0].onboarding === true
        ) {
          // setState({
          //   canseeDashboard: true,
          // });
          return window.location.assign("/overview");
        }
        else if (response.data[0].onboarding === false) {
          return window.location.assign("/individualonboarding");
        }
        else {
          return window.location.assign("/overview");
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  return (
    <React.Fragment>
      <div className="title1 shiftlefff newshft">
        <button className="title_t signupbtn newlogin" onClick={props.Logout}>
          Logout
        </button>
      </div>
      {/* {state.isloading && (
        <div id="content">
          <Spinner variant={"info"} animation="grow" />
        </div>
      )} */}
    </React.Fragment>
  );
}
