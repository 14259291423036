import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../kegennidashboard.css";
import "./../dashboard.css";
import DashboardLargeScreenNav from "../DashboardLargeScreenNav";
import AdminSideBar from "./AdminSideBar";
import AdminNavBar from "./AdminNavBar";
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../config";
import prevpage from "../../../assets/prevpage.svg";
import nextImg from "../../../assets/nextpage.svg";

const AdminTotalCompletedProfile = () => {
  const [state, setState] = useState<any>({
    last_name: "",
    first_name: "",
    profileList: [],
    profilePrev: "",
    profileNext: "",
    profileTotalPages: "",
    profileCurrentPage: "",
  });
  const {
    last_name,
    first_name,
    profileList,
    profilePrev,
    profileNext,
    profileTotalPages,
    profileCurrentPage,
  } = state;
  useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .all([
        axios.get(`${API}/dashboard/profile`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/admin/profile-completed-list/`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res1, res2) => {
          setState({
            ...state,
            first_name: res1.data.first_name,
            last_name: res1.data.last_name,
            profileList: [...res2.data.results],
            profilePrev: res2.data.previous,
            profileNext: res2.data.next,
            profileTotalPages: res2.data.total_pages,
            profileCurrentPage: res2.data.page,
          });
          //console.log(res1);
          //console.log(res2);
        })
      )
      .catch((err) => {
        //console.log(err);
      });
  }, []);
  const profileLoadOldData = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .get(`${profilePrev}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        // //console.log(res);
        setState({
          ...state,
          profileList: [...res.data.results],
          profileNext: res.data.next,
          profilePrev: res.data.previous,
          profileTotalPages: res.data.total_pages,
          profileCurrentPage: res.data.page,
        });
        // //console.log(res);
      })
      .catch((err) => {
        // //console.log(err);
      });
  };
  const profileLoadNewData = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .get(`${profileNext}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        // //console.log(res);
        setState({
          ...state,
          profileList: [...res.data.results],
          profileNext: res.data.next,
          profilePrev: res.data.previous,
          profileTotalPages: res.data.total_pages,
          profileCurrentPage: res.data.page,
        });
        // //console.log(res);
      })
      .catch((err) => {
        // //console.log(err);
      });
  };
  return (
    <>
      <Container fluid={true} className="contann122">
        <AdminNavBar overview={true} />
        <Row>
          <AdminSideBar overview={true} />
          <Col md={10} sm={12} className="prm newprm">
            <DashboardLargeScreenNav title="Total Completed Profiles" />
            <Row>
              <Col md={12} className="kisls">
                <button
                  className="apptbackbtn"
                  onClick={() => window.location.assign("/adminoverview")}
                >
                  &#8592; Back
                </button>
                <div className="kdashheader npps">
                  <div className="fjss">
                    <div>
                      {" "}
                      <span className="kdashheaderlight idds">
                        Hi{" "}
                        <span className="ksname">
                          {first_name ? first_name + " " + last_name : ""}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="messageerit findbelow">
                  Find below a list of all members who have completed their
                  professional profiles
                </div>
                <div>
                  <div className="totallistbodyttl">
                    <div className="totalname">Name</div>
                    <div className="totalarea">Thematic Area</div>
                  </div>
                  <div>
                    {profileList.length > 0 &&
                      profileList.map((x, i) => (
                        <div className="totallistbody" key={i}>
                          <div className="totalname">
                            <div className="mobottl">Name</div>
                            <div>{x.user_name}</div>
                            <div className="totalsubname">{x.email}</div>
                          </div>
                          <div className="totalarea">
                            <div className="mobottl">Thematic Area</div>
                            <span>{x.thematic_area}</span>
                          </div>
                        </div>
                      ))}
                  </div>

                  {profileList.length > 0 && (
                        <div className="next_page apptpage">
                          <div>
                            Displaying{" "}
                            <span className="page_num">
                              {profileCurrentPage}
                            </span>{" "}
                            out of{" "}
                            <span className="page_num">
                              {profileTotalPages}
                            </span>
                          </div>
                          <div>
                            {profilePrev && (
                              <img
                                className="page_change"
                                src={prevpage}
                                onClick={profileLoadOldData}
                                alt="previous page"
                              />
                            )}
                            {profileNext && (
                              <img
                                onClick={profileLoadNewData}
                                className="page_change"
                                src={nextImg}
                                alt="next page"
                              />
                            )}
                          </div>
                        </div>
                      )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminTotalCompletedProfile;
