import * as React from "react";
import "../Home/c4c.css";
import "./About.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "../HomeComponents/newnavbar";
import Footer from "../HomeComponents/newfooter";
import ClientReview from "../HomeComponents/HearFromClients";
import { useEffect } from "react";
import tick from "../../../assets/tick.png";
import prof1 from "../../../assets/professional1.svg";
import prof2 from "../../../assets/professional2.svg";
import prof3 from "../../../assets/professional3.svg";
import prof4 from "../../../assets/professional4.svg";
import sec1 from "../../../assets/fistup.svg";
import sec2 from "../../../assets/people.svg";
import box from "../../../assets/forindividuals.png";
import box1 from "../../../assets/forteams.png";
import greentick from "../../../assets/greentick.png";

export const AboutC4C = () => {
  useEffect(() => {
    window.scrollTo(-0, -0);
  });
  return (
    <div>
      <Navbar about={true} />
      <Container className="homecontainer mobilepaddingredo" fluid={true}>
        <Row className="aboutfirstsec">
          <div className="aboutfirsthead">ABOUT CLARITY FOR COMMUNITIES</div>
          <div className="aboutfirsttxt">
            Clarity for Communities is Nigeria's leading community management
            platform that helps you organize your community, understand and
            reenergize your members with insights and tools that improve their
            experience.
            <br />
            The platform is completely customizable to suit your community's
            needs.
          </div>
        </Row>
        <Row className="abtwithclarityrow">
          <div className="abtwithclarity">
            With C4C all year long
            <br />
            Communities can
          </div>
          <Col className="withclaritytxt" md={5} sm={12}>
            <div className="abttick">
              <img className="tickdash1" src={tick} alt="tick" />
              <div className="tickdash">{""}</div>
              <img className="tickdash1" src={tick} alt="tick" />
              <div className="tickdash">{""}</div>
              <img className="tickdash1" src={tick} alt="tick" />
              <div className="tickdash">{""}</div>
              <img className="tickdash1" src={tick} alt="tick" />
              <div className="tickdash">{""}</div>
              <img className="tickdash1" src={tick} alt="tick" />
              <div className="tickdash">{""}</div>
              <img className="tickdash1" src={tick} alt="tick" />
            </div>
            <div className="abtticktxt">
              <div className="yearlong1">
               Onboard and gather useful members' information seamlessly
              </div>
              <div className="yearlong2">
               Collect and track payments such as dues and contributions
              </div>
              <div className="yearlong3">
               Provide useful personal and career development opportunities and recommendations to members
              </div>
              <div className="yearlong3">
               Monitor community competency distribution and deploy members on project opportunities easily
              </div>
              <div className="yearlong3">
                Manage community interaction and expand membership offering
              </div>
              <div className="yearlong3">
                Personalize your members experience and help them stay on top of their game
              </div>
            </div>
          </Col>
          <Col className="withclarityimg" md={7}>
            <div className="profimg1">
              <img className="profimg1a" src={prof1} alt="professional" />
              <img className="profimg1b" src={prof2} alt="professional" />
            </div>
            <div className="profimg2">
              <img className="profimg2a" src={prof3} alt="professional" />
              <img className="profimg2b" src={prof4} alt="professional" />
            </div>
          </Col>
        </Row>
        <Row className="abthapprod">
          <div className="abtprod">
            Happily productive people and organisations are high performers and
            more innovative.
          </div>
          <div className="abtprodsec">
            <div className="prodsec1">
              <img className="greenimg1" src={sec1} />
            </div>
            <div className="prodsec2">
              <div className="for1">
                For{" "}
                <span className="prodindi">
                  {" "}
                  Members <img className="indibox" src={box} />
                </span>
              </div>
              <div>
                <div className="indilist">
                  <img className="prodgreentick" src={greentick} />
                  <span>Organized onboarding</span>
                </div>
                <div className="indilist">
                  <img className="prodgreentick" src={greentick} />
                  <span>Access to timely information and resources</span>
                </div>
                <div className="indilist">
                  <img className="prodgreentick" src={greentick} />
                  <span>Better productivity</span>
                </div>
                <div className="indilist">
                  <img className="prodgreentick" src={greentick} />
                  <span>Progressive growth</span>
                </div>
                <div className="indilist">
                  <img className="prodgreentick" src={greentick} />
                  <span>Relationship building</span>
                </div>
                <div className="indilist">
                  <img className="prodgreentick" src={greentick} />
                  <span>Less anxiety</span>
                </div>
              </div>
            </div>
            <div className="prodsec2 prodsec3">
              <div className="for1">
                For{" "}
                <span className="prodindi">
                  Managers
                  <img className="indibox" src={box} />
                </span>
              </div>
              <div>
                <div className="indilist">
                  <img className="prodgreentick grntick" src={greentick} />
                  <span>High performing communities </span>
                </div>
                <div className="indilist">
                  <img className="prodgreentick grntick" src={greentick} />
                  <span>
                    Intelligence on community members' strengths and weaknesses
                  </span>
                </div>
                <div className="indilist">
                  <img className="prodgreentick grntick" src={greentick} />
                  <span>Easy payment collection</span>
                </div>
                <div className="indilist">
                  <img className="prodgreentick grntick" src={greentick} />
                  <span>Improve members' engagement & retention</span>
                </div>
                <div className="indilist">
                  <img className="prodgreentick grntick" src={greentick} />
                  <span>More interactive communities</span>
                </div>
                <div className="indilist">
                  <img className="prodgreentick grntick" src={greentick} />
                  <span>Easy distribution of resources</span>
                </div>
                <div className="indilist">
                  <img className="prodgreentick grntick" src={greentick} />
                  <span>Drive performance and effectiveness</span>
                </div>
                <div className="indilist">
                  <img className="prodgreentick grntick" src={greentick} />
                  <span>Foster a better collaborative spirit</span>
                </div>
              </div>
            </div>
            <div className="prodsec4">
              <img className="sec4img" src={sec2} />
            </div>
          </div>
        </Row>
      </Container>
      {/* <ClientReview /> */}
      <Footer />
    </div>
  );
};
