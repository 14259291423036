import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./kegennidashboard.css";
import SideBarNewDashboard from "./SideBarNewDashboard";
import Axios, { AxiosResponse } from "axios";
import { API } from "../../config";
import Button from "react-bootstrap/Button";
import { CirclePie } from "salad-ui.chart";
import failedNotice from "../../assets/failedNotice.png";
import Modal from "react-bootstrap/Modal";
import DashboardNav from "./DashboardNavBar";
import DashboardLargeScreenNav from "./DashboardLargeScreenNav";
import caution from "../../assets/caution.png";
import secondlogo from "../../assets/image 2.png";
import FreeOverviewCard from "./overviewcardsfree";
import PaidOverviewCard from "./overviewcardspaid";
import { Link } from "react-router-dom";
import noplan from "../../assets/oopsimg.svg";
import HorizontalBar from "./HorizontalBar";
import calendar from "../../assets/calendar.png";
import greentick from "../../assets/greentick.png";
import greytick from "../../assets/greytick.png";
import line from "../../assets/progressline.png";

interface State {
  fullname: string;
  careerbussines: any;
  jobfunctionchartdata: any;
  averagecompetencechartdata: any;
  strongcompetencechartdata: any;
  client: any;
  successMsg: boolean;
  errorMessage: string;
  isLoading: boolean;
  showWarning: boolean;
  width: number;
  onlyfree: boolean;
  showfullresult: boolean;
  appointmentDate: string;
  appointmentTime: string;
  user_onboarding: boolean;
  personal_info: boolean;
  profile_builder: boolean;
  payment: boolean;
  erit_team: boolean;
  appointment_scheduler: boolean;
  assessment: boolean;
  appointment_status: string;
  checkassess: boolean,
}
class NewDashboard extends React.Component {
  state: State = {
    fullname: "",
    client: [],
    careerbussines: [],
    jobfunctionchartdata: [],
    averagecompetencechartdata: [],
    strongcompetencechartdata: [],
    errorMessage: "",
    successMsg: false,
    isLoading: false,
    showWarning: false,
    onlyfree: false,
    width: 100,
    showfullresult: true,
    appointmentDate: "",
    appointmentTime: "",
    user_onboarding: false,
    personal_info: false,
    profile_builder: false,
    payment: false,
    erit_team: false,
    appointment_scheduler: false,
    assessment: false,
    appointment_status: "",
    checkassess: false,
  };
  submitRetakeAssessment = (e) => {
    e.preventDefault();
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken ? JSON.parse(availableToken) : "";
    const data = {};
    Axios.get<any, AxiosResponse<any>>(`${API}/retakeassessment`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        //console.log(res.data);
        window.location.assign("/assessmentphaseone");
      })
      .catch((err) => {
        if (err) {
          //console.log(err);
        }
      });
  };
  componentWillMount() {
    const self: any = this;
    const availableToken = localStorage.getItem("userToken");
    const token: string = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    Axios.get(`${API}/erit-progress`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        //console.log(response);
        this.setState({
          user_onboarding: response.data[0].onboarding,
          personal_info: response.data[0].settings,
          profile_builder: response.data[0].profile_builder,
          assessment: response.data[0].completed_assessment,
          payment: response.data[0].payment,
          erit_team: response.data[0].joined_team,
          appointment_scheduler: response.data[0].appointment_scheduler,
        });
        if (response.data[0].settings === false) {
          return self.props.history.push("/dashboardsettings");
        }
        // if (response.data[0].profile_builder === false) {
        //   return self.props.history.push("/profilebuilder");
        // }
      })
      .catch((err) => {
        //console.log(err);
      });
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    this.getUserInfo();
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    const data = {};
    Axios.all([
      Axios.get<any, AxiosResponse<any>>(`${API}/dashboard/user-appointment/`, {
        headers: { Authorization: `Token ${token}` },
      }),
      Axios.get<any, AxiosResponse<any>>(
        `${API}/dashboard/appointment-status/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      ),
    ])
      .then(
        Axios.spread((response, response2) => {
          //console.log(response);
          //console.log(response2);
          if (response.status === 200 && response2.status === 200) {
            this.setState({
              successMsg: true,
              isLoading: false,
              appointmentDate: response.data.date,
              appointmentTime: response.data.time,
              appointment_status: response2.data.appointment_status,
            });
          }
          if (this.state.assessment === true) {
            Axios.all([
              Axios.get<any, AxiosResponse<any>>(`${API}/paiddashboard`, {
                headers: { Authorization: `Token ${token}` },
              }),
            ])
              .then(
                Axios.spread((response3) => {
                  //console.log(response3);
                  if (response3.status === 200) {
                    this.setState({
                      client: response3.data[0],
                      careerbussines:
                        response3?.data[0]?.career_business_expression[0],
                      jobfunctionchartdata:
                        response3?.data[0]?.job_function_fit?.graph,
                      averagecompetencechartdata:
                        response3?.data[0]?.average_career_competences?.graph,
                      strongcompetencechartdata:
                        response3?.data[0].strong_career_competences.graph,
                      fullname: response3.data[0].full_name,
                    });
                  }
                })
              )
              .catch((err) => {});
          }
        })
      )
      .catch((error) => {
        //console.log(error.response);
        if (error && error.response && error.response.data) {
          this.setState({
            // errorMessage: error.response.data[0].message,
            errorMessage: error.response.message,
            isLoading: false,
          });
        }
        this.setState({
          errorMessage: "failed",
          isLoading: false,
        });
      });
  }
  assessmentCheck = () => {
    if (this.state.payment === true) {
      return window.location.assign("/assessmentphaseone");
    } else {
      return this.OpenNotPaidWarning();
    }
  };
  paymentForReformTeamCheck = () => {
    if (this.state.payment === true) {
     return window.location.assign("/team_reg");
    } else {
      return this.OpenNotPaidWarning();
    }
  };
  appointmentPaymentCheck = () => {
    if (this.state.payment === true && this.state.assessment === true) {
      return window.location.assign("/scheduleappointments");
    } else if (this.state.payment === false){
      this.OpenNotPaidWarning();
    }
    else if(this.state.assessment === false) {
      this.OpenAssessmentCheckWarning();
    }
  }
  getUserInfo = (): void => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    Axios.get(`${API}/currentuser`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response?.data));
          this.setState({
            fullname:
              response.data[0].first_name + " " + response.data[0].last_name,
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  checkIfUserHasMadePaymentForFullResult = () => {
    return window.location.assign("/thirdpary/fullresult");
  };
  checkIfUserHasMadePayment = () => {
    const availableToken: string | null = localStorage.getItem("userToken");
    const token: string = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    Axios.get<any, AxiosResponse<any>>(`${API}/paymentstatus`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        //console.log(response);
        if (
          response?.data[0]?.direction_plan ||
          response?.data[0]?.growth_plan ||
          response?.data[0]?.insight_plan === true
        ) {
          return this.setState({
            showfullresult: true,
          });
        }
        if (response?.data[0]?.free_plan === true) {
          return this.setState({
            showfullresult: true,
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  CloseWarning = () => {
    this.setState({
      showWarning: false,
    });
  };
  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  handleChatCheck = () => {
    this.setState({ isLoading: true });
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    Axios.get<any, AxiosResponse<any>>(`${API}/paymentstatus`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        if (response?.data[0]?.direction_plan === true) {
          return window.location.assign("/councellordates");
        }
        if (response?.data[0]?.direction_plan === false) {
          return window.location.assign("/councellorfee");
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  openWarning = () => {
    this.setState({
      showWarning: true,
    });
  };
  CloseNotPaidWarning = () => {
    this.setState({
      onlyfree: false,
    });
  };
  OpenNotPaidWarning = () => {
    this.setState({
      onlyfree: true,
    });
  };
  CloseAssessmentCheckWarning = () => {
    this.setState({
      checkassess: false,
    });
  };
  OpenAssessmentCheckWarning = () => {
    this.setState({
      checkassess: true,
    });
  };
  render() {
    const {
      fullname,
      client,
      careerbussines,
      jobfunctionchartdata,
      averagecompetencechartdata,
      strongcompetencechartdata,
      isLoading,
      width,
      showfullresult,
      appointmentDate,
      appointmentTime,
      user_onboarding,
      personal_info,
      profile_builder,
      payment,
      erit_team,
      appointment_scheduler,
      assessment,
      appointment_status,
    } = this.state;
    // //console.log(client);
    // //console.log(showfullresult);
    return (
      <>
        <Container fluid={true} className="contann122">
          <DashboardNav overview={true} />
          <Row>
            <SideBarNewDashboard overview={true} />
            <Col md={10} sm={12} className="prm newprm">
              <DashboardLargeScreenNav title="Overview" />
              <Row>
                <Col md={12} className="kisls newkisls">
                  <div className="kdashheader npps">
                    <div className="fjss dd">
                      <div className="hd">
                        {" "}
                        <span className="kdashheaderlight idds">
                          Hi{" "}
                          <span className="ksname">
                            {" "}
                            {fullname ? fullname : ""}
                          </span>
                        </span>
                        <div className="smalls"></div>
                      </div>
                      <div className="">
                        <div className="kprofile psdd">
                          Profile
                          <div className="kprofile2 idds sowws psdd">
                            {client.profile}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="messageerit">
                      Welcome to ERIT experience!
                    </div> */}
                    <div className="">
                      {/* <Link to="/councellorchat"> */}
                      {/* <Link to="/comingsoon">
                        <Button className="retaketest">Message Erit</Button>
                      </Link> */}
                      {client.length == 0 && this.state.payment && (
                        <Link to="/assessmentphaseone">
                          <Button className="retaketest">
                            Take Assessment
                          </Button>
                        </Link>
                      )}
                      {typeof client === "object" &&
                        client.length !== 0 &&
                        this.state.payment && (
                          <Button
                            className="retaketest2 retakebtnn"
                            onClick={this.openWarning}
                          >
                            Retake Assessment
                          </Button>
                        )}
                    </div>
                  </div>
                  <hr className="listdvd" />
                  <div className="apptdetails">
                    <div className="deetsec1">
                      <img className="calicon" src={calendar} alt="calendar" />
                    </div>
                    <div className="deetsec2">
                      <div className="deetttl">Appointment Details</div>
                      {appointment_status === "confirmed" && (
                        <div className="deettxt">
                          Your Appointment with ERIT has been confirmed for{" "}
                          <span className="apptdeetbold">
                            {appointmentDate}
                          </span>{" "}
                          at{" "}
                          <span className="apptdeetbold">
                            {appointmentTime}
                          </span>
                          .
                        </div>
                      )}
                      {appointment_status === "pending" && (
                        <div className="deettxt">
                          You do not have a scheduled appointment.
                        </div>
                      )}
                      {appointment_status === "unconfirmed" && (
                        <div className="deettxt">
                          Your Appointment with ERIT scheduled for{" "}
                          <span className="apptdeetbold">
                            {appointmentDate}
                          </span>{" "}
                          at{" "}
                          <span className="apptdeetbold">
                            {appointmentTime}
                          </span>{" "}
                          is awaiting approval.
                        </div>
                      )}
                    </div>
                    <div className="deetsec3">
                      <button
                        className={
                          assessment ? "reschbtn" : "reschbtn disableresch"
                        }
                        onClick={() => {
                          if (assessment === true) {
                            window.location.assign("/scheduleappointments");
                          }
                        }}
                      >
                        {appointment_status === "pending"
                          ? "SCHEDULE"
                          : "RESCHEDULE"}
                      </button>
                    </div>
                  </div>
                  <div className="listsecc">
                    <div className="listheadd">
                      <div className="listttll">Track your progress</div>
                      {/* <div className="listtxtt">
                        Leverage the ERIT experience by ticking all these boxes
                      </div> */}
                    </div>
                    <hr />
                    <div className="listlist">
                      <img className="progressline" src={line} alt="line" />
                      {/* <div className="overviewlist">
                        <img
                          className="listtick"
                          src={user_onboarding ? greentick : greytick}
                          alt="tick"
                        />
                        <span
                          className={user_onboarding ? "progress-check" : ""}
                        >
                          User Onboarding
                        </span>
                      </div> */}
                      <div
                        className="overviewlist"
                        onClick={() =>
                          window.location.assign("/dashboardsettings")
                        }
                      >
                        <img
                          className="listtick"
                          src={personal_info ? greentick : greytick}
                          alt="tick"
                        />
                        <span className={personal_info ? "progress-check" : ""}>
                          Complete Personal Profile
                        </span>
                      </div>
                      <div
                        className="overviewlist"
                        onClick={() =>
                          window.location.assign("/profilebuilder")
                        }
                      >
                        <img
                          className="listtick"
                          src={profile_builder ? greentick : greytick}
                          alt="tick"
                        />
                        <span
                          className={profile_builder ? "progress-check" : ""}
                        >
                          Complete Professional Profile
                        </span>
                      </div>

                      <div
                        className="overviewlist"
                        onClick={() => this.assessmentCheck()}
                      >
                        <img
                          className="listtick"
                          src={assessment ? greentick : greytick}
                          alt="tick"
                        />
                        <span className={assessment ? "progress-check" : ""}>
                          Complete Competency Assessment
                        </span>
                      </div>
                      <div
                        className="overviewlist"
                        onClick={() =>
                          window.location.assign("/dashboardsubscriptionplan")
                        }
                      >
                        <img
                          className="listtick"
                          src={payment ? greentick : greytick}
                          alt="tick"
                        />
                        <span className={payment ? "progress-check" : ""}>
                          Make Payment
                        </span>
                      </div>

                    </div>
                  </div>
                  <hr className="listdvd" />
                  {client?.career_business_expression &&
                    typeof client == "object" && (
                      <>
                        <div className="yourclarityres">
                          Your Clarity Assessment Result
                        </div>
                        <div className="resultsec2 lswid" id="seek">
                          <div className="csfitscore2">
                            Your Career Fitness Score
                          </div>
                          <div className="resultsec22">
                            <CirclePie
                              width={190}
                              height={190}
                              strokeWidth={5}
                              labelColor={"#fff"}
                              labelFontSize={"38px"}
                              strokeColor={"#fff"}
                              railColor={"#17375c77"}
                              fillColor={"#001833"}
                              percent={client?.career_fitness?.score}
                              padding={0}
                            />
                          </div>
                          <div className="csfitscore" id="drivers">
                            <div className="divide"></div>{" "}
                            <div className="csfitscore1">
                              Your Career Fitness Score
                            </div>
                            <div className="vbnc1">
                              {" "}
                              {client?.career_fitness?.heading}{" "}
                            </div>
                            <div className="csbody">
                              {client?.career_fitness?.body}
                            </div>
                          </div>
                        </div>
                        <div className="reess" id="personality">
                          <div className="resultsec3">
                            <div className="careerpersonalityheader">
                              Career Personality type
                            </div>
                            <div className="reskwrap">
                              <div className="csfitscore1 reskheader">
                                Your Career Personality type
                              </div>
                              <div className="cptext">
                                {
                                  client?.career_personality_type
                                    ?.short_description
                                }
                              </div>
                            </div>
                          </div>
                          <div className="resultsec31">
                            <div className="col-md-6">
                              <img
                                src={secondlogo}
                                className="secondlogo pds"
                                alt="secondlogo"
                              />
                            </div>
                            <div className="resultt col-md-6">
                              {client?.career_personality_type?.graph?.map(
                                (data, index) => {
                                  return (
                                    <div className="">
                                      <div className="ttp">{data.name}</div>
                                      <HorizontalBar
                                        value={data.value.value1}
                                      />
                                      <div className="btmwrap">
                                        {" "}
                                        <div>{data.value.name1}</div>
                                        <div>{data.value.name2}</div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                          <div className="resultsec3">
                            <div className="reskwrap">
                              <div className="career221">
                                {client?.career_personality_type?.full_body}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* {!showfullresult && (
                          <FreeOverviewCard
                            OpenModal={() => this.OpenNotPaidWarning}
                          />
                        )} */}
                        {showfullresult && <PaidOverviewCard />}
                        <div
                          className="insightss col-md-11"
                          onClick={() =>
                            this.checkIfUserHasMadePaymentForFullResult()
                          }
                        >
                          View Full Insight
                        </div>
                      </>
                    )}
                  {client.length == 0 && !isLoading && (
                    <div className="norec pad23">
                      <img
                        src={noplan}
                        className="norecommendations"
                        alt="norecommendations"
                      />
                      <div className="udont1">Oops!!!</div>
                      <div className="udont">
                        Please remember to complete the Competency Assessment
                      </div>
                      <div className="udont">
                        {" "}
                        <span
                          className="takeassesment12"
                          onClick={this.assessmentCheck}
                        >
                          Take Assessment
                        </span>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Modal show={this.state.showWarning} onHide={this.CloseWarning}>
              <Modal.Body>
                Please note that retaking the assessment would require you to
                make payment to view the result
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btnws"
                  variant="secondary"
                  onClick={this.CloseWarning}
                >
                  Back
                </Button>
                <Button
                  variant="danger"
                  className="btnws"
                  onClick={this.submitRetakeAssessment}
                >
                  Continue
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={this.state.onlyfree}
              centered={true}
              onHide={this.CloseNotPaidWarning}
            >
              <Modal.Body>
                <div className="text-center">
                  {" "}
                  <img
                    src={failedNotice}
                    className="failedNotice"
                    alt="failedNotice"
                  />{" "}
                </div>
                <div className="nono"> Oh No! </div>
                <div className="nonosubtxt">
                  This package is not available on this plan. Upgrade your
                  subscription.
                </div>
                <div className="text-center">
                  <button
                    className="nonobtn"
                    onClick={() =>
                      window.location.assign("/dashboardsubscriptionplan")
                    }
                  >
                    View current plan
                  </button>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.checkassess}
              centered={true}
              onHide={this.CloseAssessmentCheckWarning}
            >
              <Modal.Body>
                <div className="text-center">
                  {" "}
                  <img
                    src={noplan}
                    className="takeassess"
                    alt="failedNotice"
                  />{" "}
                </div>
                <div className="nono"> Oops! </div>
                <div className="nonosubtxt">
                  You need to complete the competency assessment before booking an appointment.
                </div>
                <div className="text-center">
                  <button
                    className="nonobtn"
                    onClick={this.assessmentCheck}
                  >
                    Take assessment
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </Row>
        </Container>
      </>
    );
  }
}
export default NewDashboard;
