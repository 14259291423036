import React, { useState, useEffect } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import "./App.css";
import { About } from "./Components/NotUsed/About/About";
import { ClarityForTeams } from "./Components/Home/ClarityForTeams/clarityforteams";
import { RecruitmentAnalysisForm } from "./Components/Home/Forms/RecruitmentAnalysisForm";
import AssessmentFirstPhase from "./Components/Home/Assesment/AssessmentPhaseone";
import AssessmentFirstPhaseComplete from "./Components/Home/Assesment/AssessmentPhaseonecomplete";
import AssessmentSecondPhase from "./Components/Home/Assesment/AssessmentPhaseTwo";
import AssessmentSecondPhaseComplete from "./Components/Home/Assesment/AssessmentPhaseTWOcomplete";
import AssessmentThirdPhase from "./Components/Home/Assesment/AssessmentPhaseThree";
import AssessmentThirdPhaseComplete from "./Components/Home/Assesment/AssessmentPhaseTHREEComplete";
import Assessmentfourthphase from "./Components/Home/Assesment/Assessmentstagefour/AssessmentPhaseFour";
import Assessmentfourthphase_1 from "./Components/Home/Assesment/Assessmentstagefour/AssessmentPhaseFour_1";
import { SelectPaymentPlan } from "./Components/Home/Assesment/SelectPaymentPlan";
import AssessmentFifthPhase from "./Components/Home/Assesment/AssessmentPhaseFive";
import AssessmentSixthPhase from "./Components/Home/Assesment/AssessmentPhaseSix";
import AssessmentSeventhPhase from "./Components/Home/Assesment/AssessmentPhaseSeven";
import AssessmentFourthPhaseComplete from "./Components/Home/Assesment/AssessmentPhaseFOURCOMPLETE";
import AssessmentFifthPhaseComplete from "./Components/Home/Assesment/Assessmentphasefivecomplete";
import AssessmentSixthPhaseComplete from "./Components/Home/Assesment/Assessmentsixthphasecomplete";
import AssessmentSeventhPhaseComplete from "./Components/Home/Assesment/Assessmentphasesevencompleted";
import SignUpKigenni from "./Components/Home/SignUp Kigenni/SignUpKigenni";
import KigenniDashboard from "./Components/Dashboard/KigenniDashoard";
import ThirdPartyEmployersResult from "./Components/Dashboard/ThirdPartyEmployersResult";
import SignInKigenni from "./Components/Home/SignIn/SignIn";
import ForgotPassword from "./Components/Home/ForgotPassword/ForgotPassword";
import ResetPassword from "./Components/Home/ResetPassword/ResetPassword";
import EmailVerification from "./Components/Home/SignUpEmailVerification/SignUpEmailVerification";
import PaymentSummary from "./Components/Dashboard/paymentsummary";
import Testing from "./Components/Dashboard/Testing";
import OverPaid from "./Components/Dashboard/Overpaid";
import Pending from "./Components/Dashboard/Pending";
import CouncellorPaymentSummary from "./Components/Dashboard/counsellorpaymentsummary";
import CouncellorDates from "./Components/Dashboard/CouncellorDates";
import NewDashboard from "./Components/Dashboard/NewDashboard";
import NewDashboardJobOpportunities from "./Components/Dashboard/NewDashboardJobOpportunities";
import NewDashboardSupport from "./Components/Dashboard/NewDashboardSupport";
import NewDashboardSettings from "./Components/Dashboard/DashboardSettings";
import NewDashboardChat from "./Components/Dashboard/NewDashboardChat";
import CounsellorsRecommendation from "./Components/Dashboard/NewCouncellorsRecommendation";
import NewDashboardSubsriptionPlan from "./Components/Dashboard/NewDashboardSubsciptionPlan";
import CouncellorBookings from "./Components/Dashboard/CouncellorBookings";
import NewDashboardFullInsight from "./Components/Dashboard/NewDashboardFullinsight";
import CounsellorOverview from "./Components/Dashboard/CouncellorDasboard/CouncellorOverview";
import CounsellorBookedSessions from "./Components/Dashboard/CouncellorDasboard/CouncellorBookedSessions";
import CounsellorRecommendation from "./Components/Dashboard/CouncellorDasboard/CouncellorRecommendation";
import CouncellorAllMessages from "./Components/Dashboard/CouncellorDasboard/CouncellorAllMessages";
import EmployersViewCheckIn from "./Components/Home/EmployerViewSignIn/EmployersViewCheckIn";
import ThirdPartyEmployerViewOne from "./Components/Dashboard/ThirdPartyEmployerViewOne";
import whatsapp from "./assets/whatsapp.png";
import SignUp from "./Components/Home/SignUp/SignUp";
import SignIn from "./Components/Home/SignIn/SignIn";
import EritPaymentSummary from "./Components/Home/PaymentSummary/PaymentSummary";
import SignUpWithParams from "./Components/Home/SignUp/SignUpWithParams";

import OrganizationOnboardingModal1 from "./Components/PreDashboardScreen/OrganizationOnboardingModal1";
import OrganizationOnboardingModal2 from "./Components/PreDashboardScreen/OrganizationOnboardingModal2";
import OrganizationOnboardingModal3 from "./Components/PreDashboardScreen/OrganizationOnboardingModal3";
import NewDashboardAllSubsriptionPlans from "./Components/Dashboard/NewDashboardAllSubscription";
import { AllPlans } from "./Components/Home/ClarityForTeams/AllPlans";
import Axios from "axios";
import { API } from "./config";
import IndividualDashboardModal from "./Components/PreDashboardScreen/IndividualDashboardModal";
import NewDashboardPayForPlan from "./Components/Dashboard/NewDashboardPayForPlan";
import CVProfileBuilder from "./Components/Dashboard/CVProfileBuilder";
import TestPdf from "./Components/Dashboard/TestPdf";
import OrganizationDashboardOverview from "./Components/Dashboard/EritOrganizationDashboard/OrganizationOverview";
import JoinTeam from "./Components/PreDashboardScreen/JoinTeam";
import OrganizationDashboardMessages from "./Components/Dashboard/EritOrganizationDashboard/OrganizationMessageList";
import OrganizationDashboardInvite from "./Components/Dashboard/EritOrganizationDashboard/OrganizationInvite";
import AdminOverview from "./Components/Dashboard/AdminDashboard/AdminOverview";
import AdminTeamMembers from "./Components/Dashboard/AdminDashboard/AdminTeamMembers";
import AdminSignIn from "./Components/Home/SignIn/AdminSignIn";
import AdminSignUp from "./Components/Home/SignUp/AdminSignUp";
import CommingSoon from "./Components/Dashboard/AdminDashboard/CommingSoon";
import ComingSoon from "./Components/Dashboard/ComingSoon";
import IndividualOnboardingModals from "./Components/PreDashboardScreen/IndividualOnboardingModals";
import ProfileBuilderNew from "./Components/Dashboard/ProfileBuilderNew";
import AdminResourceCenter from "./Components/Dashboard/AdminDashboard/AdminResourceCenter";
import AdminCreateResourceCenter from "./Components/Dashboard/AdminDashboard/AdminCreateResourceCenter";
import AdminAppointments from "./Components/Dashboard/AdminDashboard/AdminAppointments";
import UserResourceCenter from "./Components/Dashboard/AdminDashboard/UserResourceCenter";
import IndividualAppointment from "./Components/PreDashboardScreen/AppointmentScheduler";
import newTestPdf from "./Components/Dashboard/newTestPdf";
import FullResultForCounsellors from "./Components/Dashboard/CouncellorDasboard/FullResultForCounsellors";
import AdminTotalCompletedProfile from "./Components/Dashboard/AdminDashboard/AdminTotalCompletedProfile";
import AdminTotalSuccessFulPayments from "./Components/Dashboard/AdminDashboard/AdminTotalSuccessfulPayments";
import AdminTotalCommunityMembers from "./Components/Dashboard/AdminDashboard/AdminTotalCommunityMembers";
import AdminTotalCorporateMembers from "./Components/Dashboard/AdminDashboard/AdminTotalCorporateMembers";
import AdminTotalProfessionalMembers from "./Components/Dashboard/AdminDashboard/AdminTotalProfessionalMembers";
import AdminDashboardSupport from "./Components/Dashboard/AdminDashboard/NewDashboardSupport";
import AdminViewUserCVProfile from "./Components/Dashboard/AdminDashboard/AdminViewUsersCVProfileBuilder";
import AdminAddInterviewer from "./Components/Dashboard/AdminDashboard/AdminAddInterviewer";
import InterviewerAccess from "./Components/Dashboard/AdminDashboard/InterviewerPortal/Interviewer";
import ViewIntervieweeResult from "./Components/Dashboard/AdminDashboard/InterviewerPortal/ViewIntervieweeResult";
import AdminSupport from "./Components/Dashboard/AdminDashboard/AdminSupport";

import C4CHome from "./Components/Home/Home/c4c";
import { AboutC4C } from "./Components/Home/About/aboutc4c";
import ContactC4C from "./Components/Home/ContactUs/contact_page";
import C4CPricing from "./Components/Home/Pricing/pricing";
import AdminSubscription from "./Components/Dashboard/AdminDashboard/AdminSubscription";
import AdminAllSubscriptions from "./Components/Dashboard/AdminDashboard/AdminAllSubscriptions";

const App: React.FC = () => {
  const [state, setState] = useState({
    shouldFillProfilesettings: true,
    shouldFillCvBuilder: false,
  });
  useEffect(() => {
    return () => {
      const availableToken = localStorage.getItem("userToken");
      const token: string = availableToken
        ? JSON.parse(availableToken)
        : window.location.assign("/signin");
      Axios.get(`${API}/erit-progress`, {
        headers: { Authorization: `Token ${token}` },
      })
        .then((response) => {
          //console.log(response);
          if (response.data[0].settings === false) {
            setState({
              ...state,
              shouldFillProfilesettings: true,
            });
          }
          if (response.data[0].profile_builder === false) {
            setState({
              ...state,
              shouldFillCvBuilder: true,
            });
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    };
  }, []);
  return (
    <>
      <div className="App">
        <a href="whatsapp://send?phone=+2348176100160 &text=Hello">
          <img src={whatsapp} className="whatsapp" alt="whatsapp" />
        </a>
        <div>
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path="/thirdparty/signup"
                component={SignUpKigenni}
              />
              <Route exact path="/admin/signup" component={AdminSignUp} />
              <Route exact path="/about" component={About} />
              <Route
                exact
                path="/faq"
                component={() => <Redirect to="/thirdparty/signup" />}
              />
              <Route
                exact
                path={
                  !state.shouldFillProfilesettings
                    ? "/dashboardsettings"
                    : "/overview"
                }
                component={NewDashboard}
              />
              <Route
                exact
                path="/jobopportunities"
                component={NewDashboardJobOpportunities}
              />
              <Route
                exact
                path="/clientchat"
                component={() => <Redirect to="/thirdparty/signup" />}
              />
              <Route exact path="/team_reg" component={JoinTeam} />
              {/* <Route exact path="/" component={ClarityForTeams} /> */}
              <Route
                exact
                path="/recruitmentform"
                component={RecruitmentAnalysisForm}
              />
              <Route
                exact
                path="/dashboard/personality"
                component={() => <Redirect to="/thirdparty/signup" />}
              />
              <Route
                exact
                path="/dashboard/careerfitness"
                component={() => <Redirect to="/signup/thirdpary" />}
              />
              <Route
                exact
                path="/assessmentphaseone"
                component={AssessmentFirstPhase}
              />
              <Route
                exact
                path="/assessmentphasefive"
                component={AssessmentFifthPhase}
              />
              <Route
                exact
                path="/assessmentphasefivecomplete"
                component={AssessmentFifthPhaseComplete}
              />
              <Route
                exact
                path="/assessmentphasesix"
                component={AssessmentSixthPhase}
              />
              <Route
                exact
                path="/assessmentphasesixcomplete"
                component={AssessmentSixthPhaseComplete}
              />
              <Route
                exact
                path="/assessmentphaseseven"
                component={AssessmentSeventhPhase}
              />
              <Route
                exact
                path="/assessmentphasesevencomplete"
                component={AssessmentSeventhPhaseComplete}
              />
              <Route
                exact
                path="/assessmentphasethree"
                component={AssessmentThirdPhase}
              />
              <Route
                exact
                path="/assessmentphasecomplete"
                component={AssessmentFirstPhaseComplete}
              />
              {/* <Route exact path="/" component={ClarityForTeams} /> */}
              <Route
                exact
                path="/secondphasecomplete"
                component={AssessmentSecondPhaseComplete}
              />
              <Route
                exact
                path="/thirdphasecomplete"
                component={AssessmentThirdPhaseComplete}
              />
              <Route
                exact
                path="/assessmentphasefour"
                component={Assessmentfourthphase}
              />
              <Route
                exact
                path="/assessmentphasefour1"
                component={Assessmentfourthphase_1}
              />
              <Route
                exact
                path="/assessmentphasefourcomplete"
                component={AssessmentFourthPhaseComplete}
              />
              <Route
                exact
                path="/assessmentphasetwo"
                component={AssessmentSecondPhase}
              />
              <Route exact path="/paymentplan" component={SelectPaymentPlan} />
              <Route exact path="/signup" component={SignUp} />
              <Route
                exact
                path="/signup/:amount"
                component={SignUpWithParams}
              />
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/admin/signin" component={AdminSignIn} />
              <Route
                exact
                path="/paymentsummary/:amount"
                component={EritPaymentSummary}
              />
              {/* Kegenni starts here */}
              <Route exact path="/signup/thirdpary" component={SignUpKigenni} />
              <Route
                exact
                path="/thirdpary/dashboard"
                component={() => <Redirect to="/overview" />}
              />
              <Route
                exact
                path="/counsellorsrecommendation"
                component={CounsellorsRecommendation}
              />
              <Route
                exact
                path="/dashboardsubscriptionplan"
                component={NewDashboardSubsriptionPlan}
              />
              <Route
                exact
                path="/dashboardsettings"
                component={NewDashboardSettings}
              />
              <Route
                exact
                path="/councellorchat"
                component={NewDashboardChat}
              />
              <Route
                exact
                path="/dashboardsupport"
                component={NewDashboardSupport}
              />
              <Route
                exact
                path="/counsellormessages"
                component={CouncellorAllMessages}
              />
              <Route
                exact
                path="/counsellorrecommendations"
                component={CounsellorRecommendation}
              />
              <Route
                exact
                path="/counselloroverview"
                component={CounsellorOverview}
              />
              <Route
                exact
                path="/counsellorbookings"
                component={CounsellorBookedSessions}
              />
              <Route
                exact
                path="/thirdpary/fullresult"
                component={() => <Redirect to="/fullinsight" />}
              />
              <Route
                exact
                path="/employers/result"
                component={ThirdPartyEmployersResult}
              />
              <Route
                exact
                path="/employers/result/:email"
                component={ThirdPartyEmployerViewOne}
              />
              <Route exact path="/thirdparty/overpaid" component={OverPaid} />
              <Route exact path="/allplans" component={AllPlans} />
              <Route exact path="/thirdparty/pending" component={Pending} />
              <Route exact path="/testing" component={Testing} />
              <Route
                exact
                path="/paymentsummary"
                component={NewDashboardAllSubsriptionPlans}
              />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route
                exact
                path="/resetpassword/:userid/:token"
                component={ResetPassword}
              />
              <Route
                exact
                path="/admin/result/:email"
                component={FullResultForCounsellors}
              />
              <Route
                exact
                path="/verifyemail/:userid/:token"
                component={EmailVerification}
              />
              <Route
                exact
                path="/admin-support"
                component={AdminDashboardSupport}
              />
               <Route
                exact
                path="/users_profile/:email"
                component={AdminViewUserCVProfile}
              />
              <Route
                exact
                path="/employersview"
                component={EmployersViewCheckIn}
              />
              <Route
                exact
                path="/councellorfee"
                component={CouncellorPaymentSummary}
              />
              <Route
                exact
                path="/fullinsight"
                component={NewDashboardFullInsight}
              />
              <Route
                exact
                path="/individualonboarding"
                component={IndividualOnboardingModals}
              />
              <Route
                exact
                path="/paynow/:amount/:description"
                component={NewDashboardPayForPlan}
              />
              <Route
                exact
                path="/organizationonboarding1"
                component={OrganizationOnboardingModal1}
              />
              <Route
                exact
                path="/organizationonboarding2"
                component={OrganizationOnboardingModal2}
              />
              <Route
                exact
                path="/organizationonboarding3"
                component={OrganizationOnboardingModal3}
              />
              <Route
                exact
                path="/individualdashboardonboarding"
                component={IndividualDashboardModal}
              />
              <Route exact path="/commingsoon" component={CommingSoon} />
              <Route exact path="/comingsoon" component={ComingSoon} />
              <Route exact path="/meetings" component={CouncellorBookings} />
              <Route
                exact
                path="/councellordates"
                component={CouncellorDates}
              />
              <Route exact path="/cvdashboard" component={ProfileBuilderNew} />
              <Route exact path="/generatecvold" component={TestPdf} />
              <Route exact path="/generatecv" component={newTestPdf} />
              <Route
                exact
                path="/profilebuilder"
                component={CVProfileBuilder}
              />
              <Route
                exact
                path="/organizationdashboardoverview"
                component={OrganizationDashboardOverview}
              />
              <Route
                exact
                path="/organizationdashboardmessages"
                component={OrganizationDashboardMessages}
              />
              <Route
                exact
                path="/organizationdashboardinvite"
                component={OrganizationDashboardInvite}
              />
              <Route exact path="/adminoverview" component={AdminOverview} />
              <Route
                exact
                path="/resource_center"
                component={AdminResourceCenter}
              />
              <Route
                exact
                path="/user_resource_center"
                component={UserResourceCenter}
              />
              <Route
                exact
                path="/adminteammembers"
                component={AdminTeamMembers}
              />
              <Route
                exact
                path="/create_resource"
                component={AdminCreateResourceCenter}
              />
              <Route exact path="/appointments" component={AdminAppointments} />
              <Route
                exact
                path="/scheduleappointments"
                component={IndividualAppointment}
              />
              <Route
                exact
                path="/admin/overview/totalcompletedprofile"
                component={AdminTotalCompletedProfile}
              />
              <Route
                exact
                path="/admin/overview/totalsuccessfulpayments"
                component={AdminTotalSuccessFulPayments}
              />
              <Route
                exact
                path="/admin/overview/totalcommunitymembers"
                component={AdminTotalCommunityMembers}
              />
              <Route
                exact
                path="/admin/overview/totalcorporatemembers"
                component={AdminTotalCorporateMembers}
              />
              <Route
                exact
                path="/admin/overview/totalprofessionalmembers"
                component={AdminTotalProfessionalMembers}
              />
              <Route exact path="/admin/interviewer" component={AdminAddInterviewer} />
              <Route exact path="/interviewerportal" component={InterviewerAccess} />
              <Route exact path="/:interviewer/view/:email/result" component={ViewIntervieweeResult} />
              <Route exact path="/admin/support" component={AdminSupport} />

              <Route exact path="/admin/subscription" component={AdminSubscription} />
              <Route exact path="/admin/allsubscriptions" component={AdminAllSubscriptions} />

              {/* C4C Landing Screens */}
              <Route exact path="/" component={C4CHome} />
              <Route exact path="/aboutc4c" component={AboutC4C} />
              <Route exact path="/contactus" component={ContactC4C} />
              <Route exact path="/pricing" component={C4CPricing} />
            </Switch>
          </BrowserRouter>
        </div>
      </div>
    </>
  );
};

export default App;
