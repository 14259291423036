import axios from "axios";
import * as React from "react";
import { Container, Row, Col, Alert, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import formemail from "../../../../assets/formemail.png";
import { API } from "../../../../config";
import "../../../Home/ForgotPassword/SignIn.css";
import Claritylogo from "../../../../assets/Claritylogo.png";
import "../../dashboard.css";

const InterviewerAccess = () => {
  const [state, setState] = React.useState<any>({
    access: true,
    interviewerMail: "",
    intervieweeMail: "",
    isLoading: false,
    isLoading2: false,
    successMessage: "",
    errorMessage: "",
    errorMessage2: "",
  });
  const {
    access,
    interviewerMail,
    intervieweeMail,
    isLoading,
    isLoading2,
    successMessage,
    errorMessage,
    errorMessage2,
  } = state;

  const onChange = (e) => {
    setState({
      ...state,
      interviewerMail: e.target.value,
    });
  };

  const onChange2 = (e) => {
    setState({
      ...state,
      intervieweeMail: e.target.value,
    });
  };

  const checkInterviewerMail = () => {
    setState({
      ...state,
      isLoading: true,
    });
    axios
      .get(`${API}/interviewer/?email=${interviewerMail}`)
      .then((res1) => {
        setState({
          ...state,
          access: false,
          isLoading: false,
          successMessage: res1?.data?.message,
        });
      })
      .catch((error) => {
        setState({
          ...state,
          isLoading: false,
          errorMessage: error.response.data.message,
        });
      });
  };

  const viewUserResult = () => {
    setState({
      ...state,
      isLoading2: true,
    });
    if(intervieweeMail === "") {
      setState({
        ...state,
        isLoading2: false,
        errorMessage2: "Please enter interviewee email",
      });
    }else {
      setState({
        ...state,
        isLoading2: false,
      });
      return window.location.assign(`/${interviewerMail}/view/${intervieweeMail}/result`);
    }
  };
  return (
    <>
      <Container fluid={true}>
        <img className="externallogo" src={Claritylogo} alt="Erit logo" />
        {!access && (
          <button
            className="apptbackbtn"
            onClick={() => setState({ ...state, access: true, interviewerMail: "", successMessage: "" })}
          >
            &#8592; Back
          </button>
        )}
        <Row className="kli bcbv">
          {access && (
            <Col md={6}>
              <h2 className="forgotttl">
                Welcome to the ERIT Interviewer Portal
              </h2>
              <br />
              <div className=" mjcn">
                Enter your email address to gain access
              </div>
              {errorMessage && (
                <Alert key={2} variant="danger">
                  {errorMessage}
                </Alert>
              )}
              {successMessage && (
                <Alert key={2} variant="info">
                  {successMessage}
                </Alert>
              )}
              <Form.Group className="bvbcm" controlId="formBasicEmail">
                <img src={formemail} className="formavatar" alt="formavatar" />
                <input
                  type="email"
                  className="field3 emailinput"
                  value={interviewerMail}
                  name="email"
                  onChange={onChange}
                  placeholder="Email Address"
                />
              </Form.Group>
              <Button
                variant="primary"
                className="subbtn ncn"
                type="submit"
                onClick={checkInterviewerMail}
              >
                {!isLoading ? "Submit" : "Submitting ..."}
              </Button>
            </Col>
          )}
          {!access && (
            <Col md={6}>
              <h2 className="forgotttl">
                Hi Interviewer <b>{interviewerMail}</b>
              </h2>
              <br />
              <div className=" mjcn">
                Enter the email address of the interviewee to gain access to
                their assessment result
              </div>
              {errorMessage2 && (
              <Alert key={2} variant="danger">
                {errorMessage2}
              </Alert>
            )}
            {/* {message && (
              <Alert key={2} variant="info">
                {message}
              </Alert>
            )} */}
              <Form.Group className="bvbcm" controlId="formBasicEmail">
                <img src={formemail} className="formavatar" alt="formavatar" />
                <input
                  type="email"
                  className="field3 emailinput"
                  value={intervieweeMail}
                  name="email"
                  onChange={onChange2}
                  placeholder="Email Address"
                />
              </Form.Group>
              <Button
                variant="primary"
                className="subbtn ncn"
                type="submit"
                onClick={viewUserResult}
              >
                {!isLoading2 ? "View Assessment Result" : "Processing..."}
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default InterviewerAccess;
