import * as React from "react";
import { Link } from "react-router-dom";

export interface IAppProps {}

export function NavIsLoggedOut(props: IAppProps) {
  const login = () => {
    if(window.location.pathname === "/signup" || window.location.pathname === "/signin") {
      window.location.assign("/signin");
    }
    else if(window.location.pathname === "/signup" || window.location.pathname === "/signup") {
      window.location.assign("/signin");
    }
    else {
      window.location.assign("/signin");
    }
  }
  return (
    <React.Fragment>
      <div className="title1 shiftlefff">
          <button className="title_t" onClick={login}>LOGIN</button>
      </div>
    </React.Fragment>
  );
}
