import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./kegennidashboard.css";
import avatar from "../../assets/avatar.svg";
import SideBarNewDashboard from "./SideBarNewDashboard";
import Axios, { AxiosResponse } from "axios";
import { API } from "../../config";
import noplan from "../../assets/oopsimg.svg";
import Button from "react-bootstrap/Button";
import { CirclePie } from "salad-ui.chart";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import checkcircle from "../../assets/checkcircle.png";
import DashboardUsernameheader from "./DashboardUsernameheader";
import norecommendations from "../../assets/no recommendations.png";
import DashboardNav from "./DashboardNavBar";
import DashboardLargeScreenNav from "./DashboardLargeScreenNav";
import { Link } from "react-router-dom";
import { report } from "process";
import "./dashboard.css";
import cart from "../../assets/emptycart.svg"

class NewDashboardSubsriptionPlan extends React.Component {
  state: any = {
    fullname: "",
    message: "",
    successMsg: false,
    isLoading: false,
    showWarning: false,
    plan: "",
    width: 100,
    communityPlan: false,
    freePlan: false,
    corporatePlan: false,
    professionalPlan: false,
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    Axios.get<any, AxiosResponse<any>>(`${API}/paymentstatus`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        //console.log(response);
        this.setState({
          communityPlan: response.data[0].community_membership_plan,
          freePlan: response.data[0].free_membership_plan,
          corporatePlan: response.data[0].coporate_membership_plan,
          professionalPlan: response.data[0].professional_membership_plan,
        });
      })
      .catch((error) => {
        ////console.log(error.response);
        if (error && error.response && error.response.data) {
          this.setState({
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        this.setState({
          errorMessage: "failed",
          isLoading: false,
        });
      });
  }
  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    const {
      fullname,
      message,
      isLoading,
      width,
      plan,
      communityPlan,
      freePlan,
      corporatePlan,
      professionalPlan,
    } = this.state;
    ////console.log(plan);
    return (
      <>
        <Container fluid={true} className="contann122">
          <DashboardNav subscription={true} />
          <Row>
            <SideBarNewDashboard subscription={true} />
            <Col md={10} sm={12} className="prm">
              <DashboardLargeScreenNav title={"Payment"} />
              <Row>
                <Col md={12} className="kisls">
                  <div className="kdashheade npps">
                    <DashboardUsernameheader
                      welcomeText={"A review of your payment plan"}
                    />
                    <div className="">
                      <Button className="retaketest">
                        <Link to="/paymentsummary">
                          Review your current plan
                        </Link>
                      </Button>
                    </div>
                    <div>
                      <hr />
                    </div>
                      <div>
                        <div className="activeplac">Active plan</div>
                      </div>
                   
                      <div className="norec">
                        <img
                          src={cart}
                          className="norecommendations"
                          alt="norecommendations"
                        />
                        <div className="udont1">Oops!!!</div>
                        <div className="udont">
                          You haven't paid for any plan yet
                        </div>
                      </div>
                    
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default NewDashboardSubsriptionPlan;
