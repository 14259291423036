import * as React from "react";
import "./Home.css";
import "./c4c.css";
import Navbar from "../HomeComponents/newnavbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
// import "../../../Brfirma/BRFirma-Regular.ttf";
// import "../../../Brfirma/BRFirma-Bold.ttf";
// import "../../../Brfirma/BRFirma-ExtraLight.ttf";
// import "../../../Brfirma/BRFirma-BoldItalic.ttf";
import downarrow from "../../../assets/Arrow.png";
import facebook from "../../../assets/Vectorfacebook.png";
import insta from "../../../assets/Vectorinsta.png";
import linkedin from "../../../assets/Vectorlinkedin.png";
import twitter from "../../../assets/Vectortwitter.png";
import rect from "../../../assets/Landingrectangle.png";
import circle1 from "../../../assets/circle1.svg";
import circle2 from "../../../assets/circle2.svg";
import arrow1 from "../../../assets/arrow1.svg";
import circle3 from "../../../assets/circle33.svg";
import arrow2 from "../../../assets/arrow2.svg";
import arrow3 from "../../../assets/arrow3.svg";
import circle4 from "../../../assets/circle44.svg";
import circle5 from "../../../assets/circle55.svg";
import arrow4 from "../../../assets/arrow4.svg";
import circle6 from "../../../assets/circle66.svg";
import arrow5 from "../../../assets/arrow5.svg";
import arrow6 from "../../../assets/arrow6.svg";
import ClientReview from "../HomeComponents/HearFromClients";
import Think from "../HomeComponents/ThinkThisIsYou";
import Footer from "../HomeComponents/newfooter";
import laptop from "../../../assets/laptop.svg";
import trust1 from "../../../assets/trust1.png";
import trust2 from "../../../assets/trust2.png";
import trust3 from "../../../assets/trust3.png";
import trust4 from "../../../assets/trust4.png";
import trust5 from "../../../assets/trust5.png";
import { Card } from "react-bootstrap";
import close from "../../../assets/close.png";
import border1 from "../../../assets/border1.png";
import border2 from "../../../assets/border2.png";
import border3 from "../../../assets/border3.png";
import border4 from "../../../assets/border4.png";
import border5 from "../../../assets/border5.png";
import smarrow1 from "../../../assets/smarrow1.svg";
import smarrow2 from "../../../assets/smarrow2.svg";
import smarrow4 from "../../../assets/smarrow4.svg";
import smarrow5 from "../../../assets/smarrow5.svg";
import circle3_1 from "../../../assets/circle3_1.svg";
import smcircle2 from "../../../assets/smcircle2.svg";
import smborder from "../../../assets/smborder.png";
import jaiye from "../../../assets/Jaiyeimg.svg";
import mobileimg from "../../../assets/mobilebackdrop2.svg";
import ellipse1 from "../../../assets/ellipse1.png";
import ellipse2 from "../../../assets/ellipse2.png";
import Axios from "axios";
import { API } from "../../../config";
import preloader2 from "../../../assets/preloader2.gif";
import ReactGA from "react-ga";
import AOS from "aos";
import "aos/dist/aos.css";

ReactGA.initialize("UA-151203321-1");
const C4CHome: React.FC = (props: any) => {
  const [name, setName] = React.useState("");
  React.useEffect((): any => {
    window.scrollTo(-0, -0);
    AOS.init({
      duration: 1000,
      offset: 300,
      easing: "ease-in-out",
    });
    AOS.refresh();
    // ReactGA.pageview(window.location.pathname);
  }, []);

  const [state, setState] = React.useState<any>({
    press: false,
    showPreloader: false,
    isloading: false,
    IsUser: false,
  });

  const GetStarted = () => {
    setState({
      ...state,
      press: true,
    });
  };

  const CloseGetStarted = () => {
    setState({
      ...state,
      press: false,
    });
  };

  const { press, isloading } = state;

  const getCurrentAssessmentPosition = (): void => {
    const availableToken = localStorage.getItem("userToken");
    const token: string = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    Axios.get(`${API}/progress`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        setState({
          ...state,
          isloading: false,
        });
        if (
          (response.status === 200 &&
            response.data[0].next === "phase_four_nature") ||
          response.data[0].next === "phase_four_health" ||
          response.data[0].next === "phase_four_building" ||
          response.data[0].next === "phase_four_creative"
        ) {
          return props.history.push(`/assessmentphasefour`);
        }
        if (
          (response.status === 200 &&
            response.data[0].next === "phase_four_sports") ||
          response.data[0].next === "phase_four_business" ||
          response.data[0].next === "phase_four_stem" ||
          response.data[0].next === "phase_four_humanitarian"
        ) {
          return props.history.push(`/assessmentphasefour1`);
        }
        if (response.status === 200 && response.data[0].next === "phase_one") {
          return props.history.push(`/assessment/welcome`);
        }
        if (response.status === 200 && response.data[0].next === "phase_two") {
          return props.history.push(`/assessmentphasetwo`);
        }
        if (
          response.status === 200 &&
          response.data[0].next === "phase_three"
        ) {
          return props.history.push(`/assessmentphasethree`);
        }
        if (response.status === 200 && response.data[0].next === "phase_five") {
          return props.history.push(`/assessmentphasefive`);
        }
        if (response.status === 200 && response.data[0].next === "phase_six") {
          return props.history.push(`/assessmentphasesix`);
        }
        if (
          response.status === 200 &&
          response.data[0].next === "phase_seven"
        ) {
          return props.history.push(`/assessmentphaseseven`);
        }
        if (response.status === 200 && response.data[0].next === "home") {
          return props.history.push(`/free/dashboard`);
        }
      })
      .catch((error) => {
        setState({
          ...state,
          isloading: false,
        });
      });
  };

  React.useEffect((): any => {
    window.scrollTo(-0, -0);
    setState({
      ...state,
      isloading: true,
    });
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken ? JSON.parse(availableToken) : "";
    if (token) {
      getCurrentAssessmentPosition();
    } else {
      setState({
        ...state,
        isloading: false,
      });
    }
  }, []);

  return (
    <div>
      <Navbar home={true} />
      {isloading && (
        <div className="isloadd1">
          <img src={preloader2} className="preloader2" alt="preloader" />
          <div className="text-teala">Resuming Session</div>
        </div>
      )}
      {!isloading && (
        <>
          <Container fluid={true} className="mobilepaddingredo">
            <Row md={12} className="newfirstrow">
              <img className="ellipse1" src={ellipse1} alt="ellipse" />
              <img className="ellipse2" src={ellipse2} alt="ellipse" />
              <Col md={6} className="navcontrols home1">
                {" "}
                <img className="jaiyeimg" src={jaiye} alt="happy client" />
                <img className="mobilebg" src={mobileimg} alt="happy client" />
              </Col>
              <Col md={5} sm={11} className="hometext">
                <div
                  data-aos="zoom-in-up"
                  data-aos-duration="600"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-once={false}
                >
                  <div>A reliable community management platform</div>
                  {/* <div>Let's connect all the dots...</div> */}
                  <div className="smhometext">
                    A totally customizable tool to maximize the potential of
                    your work-based and social communities.
                  </div>
                  <Link to="/signup">
                    <div className="hstarted">GET STARTED</div>
                  </Link>
                </div>
              </Col>
              <Col md={1} className="socials">
                <img src={downarrow} className="socials1" alt="arrow" />
                <a
                  href="https://free.facebook.com/pages/category/Product-Service/109680753747119/?_rdc=1&_rdr"
                  target="_blank"
                >
                  <img
                    src={facebook}
                    className="socials1 ssmedia"
                    alt="facebook"
                  />
                </a>
                <a href="https://twitter.com/askyudimy?s=08" target="_blank">
                  <img
                    src={twitter}
                    className="socials1 ssmedia"
                    alt="twitter"
                  />
                </a>
                <a href="http://instagram.com/getclarity_" target="_blank">
                  <img
                    src={insta}
                    className="socials1 ssmedia"
                    alt="instagram"
                  />
                </a>
                <a
                  href="https://ng.linkedin.com/company/yudimy"
                  target="_blank"
                >
                  <img
                    src={linkedin}
                    className="socials1 ssmedia"
                    alt="linkedin"
                  />
                </a>
              </Col>
            </Row>
            <Row md={12} className="newsecondrow">
              <div
                className="secondtitle"
              >
                Does this sound like{" "}
                <span className="secondtitle1">
                  you?{" "}
                  <img src={rect} className="secondimg" alt="purple block" />
                </span>
              </div>
              <div className="crazycircle1">
                <img
                  src={circle1}
                  className="lcircle1"
                  data-aos="fade-zoom-in"
                  alt="man sitting"
                />
                <img
                  src={arrow1}
                  className="larrow1"
                  data-aos="fade-zoom-in"
                  alt="dashed connecting arrow"
                />
                <img
                  src={smarrow1}
                  className="smarrow1"
                  data-aos="fade-zoom-in"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  alt="dashed connecting arrow"
                />
                <img
                  src={circle2}
                  className="lcircle2"
                  data-aos="flip-left"
                  data-aos-duration="600"
                  alt="man sitting"
                />
              </div>
              <div className="crazycircle2">
                <img
                  src={arrow2}
                  className="larrow2"
                  data-aos="flip-left"
                  data-aos-duration="600"
                  alt="dashed connnecting arrow"
                />
                <img
                  src={smarrow2}
                  className="smarrow2"
                  data-aos="flip-left"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                  alt="dashed connnecting arrow"
                />
                <img
                  src={circle3}
                  className="lcircle3"
                  data-aos="flip-left"
                  data-aos-duration="600"
                  alt="man standing"
                />
                <img src={circle3_1} className="smcircle1" alt="man standing" />
                <img
                  src={arrow3}
                  className="larrow3"
                  data-aos="flip-right"
                  data-aos-duration="600"
                  alt="dashed connnecting arrow"
                />
              </div>
              <div className="crazycircle3" data-aos="flip-left">
                <img
                  src={circle4}
                  className="lcircle4"
                  data-aos="flip-right"
                  data-aos-duration="600"
                  alt="woman sitting"
                />
                <img
                  src={smarrow4}
                  className="smarrow4"
                  data-aos="flip-right"
                  data-aos-duration="600"
                  alt="dashed connnecting arrow"
                />
                <img
                  src={arrow6}
                  className="larrow6"
                  data-aos="flip-right"
                  alt="dashed connnecting arrow"
                />
                <img
                  src={circle5}
                  className="lcircle5"
                  data-aos="flip-right"
                  alt="man walking"
                />
              </div>
              <div className="crazycircle4">
                <img
                  src={arrow4}
                  className="larrow4"
                  data-aos="fade-zoom-in"
                  data-aos-duration="600"
                  alt="dashed connnecting arrow"
                />
                <img
                  src={smarrow5}
                  className="smarrow5"
                  data-aos="flip-right"
                  data-aos-duration="600"
                  alt="dashed connnecting arrow"
                />
                <img
                  src={circle6}
                  className="smcircle2"
                  data-aos="flip-right"
                  data-aos-duration="600"
                  alt="woman sitting"
                />
                <img
                  src={circle6}
                  className="lcircle6"
                  data-aos="flip-left"
                  data-aos-duration="600"
                  alt="woman sitting"
                />
                <img
                  src={arrow5}
                  className="larrow5"
                  data-aos="flip-right"
                  data-aos-duration="600"
                  alt="dashed connnecting arrow"
                />
              </div>
            </Row>
            {/* {press === false ? ( */}
              <Row md={12} className="newthirdrow">
                <div className="newthirdrowtext">
                  Clarity for Communities (C4C) is a totally customisable
                  community management platform that helps work-based
                  organizations and social communities better understand, manage
                  and create value for their professional and service
                  communities. C4C offers insights and tools that improves each
                  member's community ecperience.
                </div>
                <div className="newthirdrowbtn" onClick={() => window.location.assign("/signup")}>
                  GET STARTED
                </div>
              </Row>
            {/* ) : (
              <Row md={12} className="thirdgetstarted">
                <img
                  className="gtclose"
                  src={close}
                  onClick={CloseGetStarted}
                  alt="close"
                />
                <Card
                  className="h-300 shadow-sm bg-white thirdgetstartedcard"
                >
                  <div className="thirdgthead">Start my personal Journey</div>
                  <div className="thirdgttxt" >
                    Lets give you a perception to what your community should look
                    like
                  </div>
                  <Link to="/signup">
                    <div className="thirdgtbtn1">Sign Up</div>
                  </Link>
                </Card>
                <Card
                  className="h-300 shadow-sm bg-white thirdgetstartedcard"
                  data-aos="flip-down"
                >
                  <div className="thirdgthead" data-aos="fade-right">
                    Gift a Friend
                  </div>
                  <div className="thirdgttxt" data-aos="fade-right">
                    Give a gift of success. Choose one of the three different
                    plans
                  </div>
                  <Link to="/payment">
                    <div className="thirdgtbtn2">Get Started</div>
                  </Link>
                </Card>
                <Card
                  className="h-300 shadow-sm bg-white thirdgetstartedcard"
                  data-aos="flip-right"
                >
                  <div className="thirdgthead">Use Clarity for my Team</div>
                  <div className="thirdgttxt" data-aos="fade-right">
                    Lets get your team to their highest potential and get your
                    ROI to the roof
                  </div>
                  <div className="thirdgtbtn3 unclick">Get Started</div>
                </Card>
              </Row>
            )} */}
            <Row md={12} className="newfourthrow">
              <div className="secondtitle fourthttl">
                A platform you can{" "}
                <span className="secondtitle1 trustttl">
                  trust{" "}
                  <img
                    src={rect}
                    className="secondimg fourthimg"
                    alt="purple block"
                  />
                </span>
              </div>
              <div className="fourthline"> </div>
              <div className="fourthtext">
                <div>
                  Do you need a relaible, totally customisable community
                  management tool?
                </div>
                <div>
                  Clarity for Communities is for you.
                </div>
              </div>
              <div className="homelaptop">
                <div className="homelaptop1">
                  <img
                    className="laptop"
                    src={laptop}
                    alt="laptop showing clarity dashboard"
                  />
                </div>
              </div>
              <div>
                <div className="fourthsec fourthsec1">
                  <div className="fourthsubsec">
                    <img className="border1" src={border5} alt="border" />
                    <img className="rightborder" src={smborder} alt="border" />
                    <div className="fourthsubsechov">
                      <img
                        src={trust1}
                        className="trustimg"
                        alt="mental assessment"
                      />
                      <div className="fourthsubhead" data-aos="fade-right">
                        Member onboarding and profiling
                      </div>
                      <div className="fourthsubtext" data-aos="fade-right">
                        Using our proprietary Clarity Assessment, each of your
                        community members is supported on a path of self
                        awareness, personal and professional development. This
                        one of a kind assessment highlights competency and
                        career fit.
                      </div>
                    </div>
                  </div>
                  <div className="fourthsubsec">
                    <img className="border2" src={border2} alt="border" />
                    <img
                      className="leftborder leftborder1"
                      src={smborder}
                      alt="border"
                    />
                    <div className="fourthsubsechov">
                      <img
                        src={trust2}
                        className="trustimg"
                        alt="communication"
                      />
                      <div className="fourthsubhead" data-aos="fade-right">
                        Payment Collection and Financial Management
                      </div>
                      <div className="fourthsubtext" data-aos="fade-right">
                        Collect and track payment of membership dues and
                        subscriptions, seamlessly.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fourthsec">
                  <div className="fourthsubsec hov3">
                    <img className="border3" src={border3} alt="border" />
                    <img
                      className="rightborder rightborder2"
                      src={smborder}
                      alt="border"
                    />
                    <div className="fourthsubsechov">
                      <img src={trust3} className="trustimg" alt="list" />
                      <div className="fourthsubhead">
                        Ease of communication & Resource Distribution
                      </div>
                      <div
                        className="fourthsubtext frheight"
                      >
                        At Yudimy, we are committed to your community's success.
                        Our team will work with you to capture your unique value
                        proposition, profile and brand so that it can be
                        communicated effectively to your members.
                      </div>
                    </div>
                  </div>
                  <div className="fourthsubsec hov4">
                    <img className="border4" src={border4} alt="border" />
                    <img
                      className="leftborder leftborder2"
                      src={smborder}
                      alt="border"
                    />
                    <div className="fourthsubsechov">
                      <img
                        src={trust4}
                        className="trustimg trustimgin"
                        alt="growth"
                      />
                      <div className="fourthsubhead">
                        Appointment Scheduling and Chat Function
                      </div>
                      <div className="fourthsubtext">
                        Book sessions and communicate in real time with
                        community managers, members and other internal resource
                        people.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fourthsubsec fourthposit">
                  <img className="border5" src={border5} alt="border" />
                  <div className="fourthsubsechov">
                    <img src={trust5} className="trustimg" alt="position" />
                    <div className="fourthsubhead">Totally Customizable</div>
                    <div className="fourthsubtext">
                      We are happy to understand your needs and customize the
                      tool to solve problems peculiar to your community. Our
                      product managers and developers are excited to work with
                      you and craft your idea to your needs.
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            {/* <ClientReview /> */}
            <Think />
            <Footer />
          </Container>
        </>
      )}
    </div>
  );
};

export default C4CHome;
