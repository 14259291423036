import * as React from "react";
import AdminSideBar from "./AdminSideBar";
import AdminNavBar from "./AdminNavBar";
import DashboardLargeScreenNav from "../DashboardLargeScreenNav";
import "../dashboard.css";
import { Container, Row, Col, Modal } from "react-bootstrap";
import axios from "axios";
import { API } from "../../../config";
import prevpage from "../../../assets/prevpage.svg";
import nextImg from "../../../assets/nextpage.svg";
import { toast, ToastContainer } from "react-toastify";
import none from "../../../assets/oopsimg.svg";

const AdminAddInterviewer = () => {
  const [state, setState] = React.useState<any>({
    email: "",
    first_name: "",
    last_name: "",
    interviewerMails: [],
    interviewerPrev: "",
    interviewerNext: "",
    interviewerTotalPages: "",
    interviewerCurrentPage: "",
    removeMail: "",
    removeId: "",
  });
  const {
    email,
    first_name,
    last_name,
    interviewerMails,
    interviewerPrev,
    interviewerNext,
    interviewerTotalPages,
    interviewerCurrentPage,
    removeMail,
    removeId,
  } = state;
  const [modState, setModState] = React.useState<any>({
    confirm: false,
  });
  const { confirm } = modState;
  const closeConfirmationModal = () => {
    setModState({
      ...modState,
      confirm: false,
    });
  };
  const openConfirmationModal = () => {
    setModState({
      ...modState,
      confirm: true,
    });
  };
  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      email: e.target.value,
    });
  };
  const removeMailModal = (x) => {
    setState({
      ...state,
      removeMail: x.email,
      removeId: x.id,
    });
    return openConfirmationModal();
  };
  React.useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    const data = {
      email: email,
    };
    //console.log(data);
    axios
      .all([
        axios.get(`${API}/dashboard/profile`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/interviewer-list/`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res1, res2) => {
        //   //console.log(res1);
        //   //console.log(res2);
          if (res1.status === 200 && res2.status === 200) {
            setState({
              ...state,
              email: "",
              first_name: res1?.data?.first_name,
              last_name: res1?.data?.last_name,
              interviewerMails: [...res2?.data?.results],
              interviewerPrev: res2?.data?.previous,
              interviewerNext: res2?.data?.next,
              interviewerTotalPages: res2?.data?.total_pages,
              interviewerCurrentPage: res2?.data?.page,
            });
          }
        })
      )
      .catch();
  }, []);

  const addEmail = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    const data = {
      email: email,
    };
    //console.log(data);
    axios
      .all([
        axios.post(`${API}/interviewer/`, data, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res1) => {
        //   //console.log(res1);
          if (res1.status === 200) {
            notify("Interviewer added successfully!");
            // notify(res1.data.message)
          }
          axios
            .all([
              axios.get(`${API}/interviewer-list/`, {
                headers: { Authorization: `Token ${token}` },
              }),
            ])
            .then(
              axios.spread((res2) => {
                // //console.log(res2);
                if (res2.status === 200) {
                  setState({
                    ...state,
                    email: "",
                    interviewerMails: [...res2?.data?.results],
                    interviewerPrev: res2?.data?.previous,
                    interviewerNext: res2?.data?.next,
                    interviewerTotalPages: res2?.data?.total_pages,
                    interviewerCurrentPage: res2?.data?.page,
                  });
                }
              })
            );
        })
      )
      .catch((error) => {
        //   //console.log(error)
        notify("Failed to add. Please try again later!");
      });
  };
  const removeEmail = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .all([
        axios.delete(`${API}/delete-interviewer/${removeId}/`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res1) => {
        //   //console.log(res1);
          if (res1.status === 200) {
            notify(res1.data.message);
          }
          axios
            .all([
              axios.get(`${API}/interviewer-list/`, {
                headers: { Authorization: `Token ${token}` },
              }),
            ])
            .then(
              axios.spread((res2) => {
                // //console.log(res2);
                if (res1.status === 200) {
                  setState({
                    ...state,
                    email: "",
                    interviewerMails: [...res2?.data?.results],
                    interviewerPrev: res2?.data?.previous,
                    interviewerNext: res2?.data?.next,
                    interviewerTotalPages: res2?.data?.total_pages,
                    interviewerCurrentPage: res2?.data?.page,
                  });
                }
              })
            );
          return closeConfirmationModal();
        })
      )
      .catch(() => {
        notify("Failed to remove. Please try again later!");
        return closeConfirmationModal();
      });
  };
  const unconfirmedLoadOldData = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .get(`${interviewerPrev}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        // //console.log(res);
        window.scrollTo(-0, -0);
        if (res.status === 200) {
          setState({
            ...state,
            interviewerMails: [...res?.data?.results],
            interviewerPrev: res?.data?.previous,
            interviewerNext: res?.data?.next,
            interviewerTotalPages: res?.data?.total_pages,
            interviewerCurrentPage: res?.data?.page,
          });
        }
        // //console.log(res);
      })
      .catch((err) => {
        // //console.log(err);
      });
  };
  const unconfirmedLoadNewData = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .get(`${interviewerNext}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        // //console.log(res);
        window.scrollTo(-0, -0);
        if (res.status === 200) {
          setState({
            ...state,
            interviewerMails: [...res?.data?.results],
            interviewerPrev: res?.data?.previous,
            interviewerNext: res?.data?.next,
            interviewerTotalPages: res?.data?.total_pages,
            interviewerCurrentPage: res?.data?.page,
          });
        }
        // //console.log(res);
      })
      .catch((err) => {
        // //console.log(err);
      });
  };
  const notify = (message: string) => {
    window.scrollTo(-0, -0);
    toast(message, { containerId: "B" });
  };
  return (
    <>
      <Container fluid={true} className="contann122">
        <AdminNavBar appoint={true} />
        <Row>
          <AdminSideBar appoint={true} />
          <Col md={10} sm={12} className="prm apptbg newprm">
            <DashboardLargeScreenNav title="All Interviewers" />
            <Row>
              <Col md={12} className="kisls">
                <button
                  className="apptbackbtn"
                  onClick={() => window.location.assign("/appointments")}
                >
                  &#8592; Back
                </button>
                <div className="kdashheader npps">
                  <div className="fjss">
                    <div>
                      {" "}
                      <span className="kdashheaderlight idds">
                        Hi{" "}
                        <span className="ksname">
                          {first_name ? first_name + " " + last_name : ""}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="messageerit findbelow apptmanage">
                  Manage your interviewers
                </div>
                <div className="interviewers">
                  <div className="interviewerinput">
                    <input
                      placeholder="--Enter Interviewer's Email--"
                      className="interviewermail"
                      id="interviewer"
                      type="email"
                      name="mail"
                      value={email}
                      onChange={onChange}
                    />
                    <button className="interviewerbtn" onClick={addEmail}>
                      &#43; Add
                    </button>
                  </div>
                  <div className="interviewerlistsec">
                    <h6>List of Interviewers</h6>
                    {interviewerMails.length > 0 &&
                      interviewerMails.map((x, i) => (
                        <div className="interviewerlist" key={i}>
                          <div className="interviewercard">
                            <p>{x.email}</p>
                            <p
                              className="removeinterviewer"
                              onClick={() => removeMailModal(x)}
                            >
                              Remove
                            </p>
                          </div>
                        </div>
                      ))}

                    {interviewerMails.length <= 0 && (
                      <div className="interviewerlist nointerviewersec">
                        <img
                          className="nointerviewer"
                          src={none}
                          alt="no interviewers"
                        />
                        <h5>Oops!</h5>
                        <p>You have added no interviewers</p>
                      </div>
                    )}
                  </div>
                </div>

                {interviewerMails.length > 0 && (
                  <div className="next_page apptpage">
                    <div>
                      Displaying{" "}
                      <span className="page_num">{interviewerCurrentPage}</span>{" "}
                      out of{" "}
                      <span className="page_num">{interviewerTotalPages}</span>
                    </div>
                    <div>
                      {interviewerPrev && (
                        <img
                          className="page_change"
                          src={prevpage}
                          onClick={unconfirmedLoadOldData}
                          alt="previous page"
                        />
                      )}
                      {interviewerNext && (
                        <img
                          onClick={unconfirmedLoadNewData}
                          className="page_change"
                          src={nextImg}
                          alt="next page"
                        />
                      )}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ToastContainer
        enableMultiContainer
        containerId={"B"}
        toastClassName="bg-info text-white"
        hideProgressBar={true}
        position={toast.POSITION.TOP_CENTER}
      />
      <Modal show={confirm} onHide={closeConfirmationModal} centered>
        <Modal.Body>
          <div className="exittxt">
            Remove <span className="exittxtsub">{removeMail}</span> from the
            interviewer list?
          </div>
          <div className="exitbtns">
            <button className="exitbtn1" onClick={closeConfirmationModal}>
              No
            </button>
            <button className="exitbtn2" onClick={removeEmail}>
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminAddInterviewer;
