import * as React from "react";
import AdminSideBar from "./AdminSideBar";
import AdminNavBar from "./AdminNavBar";
import DashboardLargeScreenNav from "../DashboardLargeScreenNav";
import "../dashboard.css";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useState } from "react";
import prevpage from "../../../assets/prevpage.svg";
import nextImg from "../../../assets/nextpage.svg";
import close from "../../../assets/off_close.png";
import axios from "axios";
import { API } from "../../../config";
import { toast, ToastContainer } from "react-toastify";

const AdminAppointments = () => {
  const [state, setState] = useState<any>({
    confirmed: true,
    schedule: false,
    approve: false,
    first_name: "",
    last_name: "",
    confirmedList: [],
    unconfirmedList: [],
    confirmedPrev: "",
    confirmedNext: "",
    confirmedTotalPages: "",
    confirmedCurrentPage: "",
    unconfirmedPrev: "",
    unconfirmedNext: "",
    unconfirmedTotalPages: "",
    unconfirmedCurrentPage: "",
    emailText: "",
    unconfirmedUserTime: "",
    unconfirmedUserDate: "",
    userId: 0,
  });
  const {
    confirmed,
    schedule,
    approve,
    first_name,
    last_name,
    confirmedList,
    unconfirmedList,
    confirmedPrev,
    confirmedNext,
    confirmedTotalPages,
    confirmedCurrentPage,
    emailText,
    unconfirmedPrev,
    unconfirmedNext,
    unconfirmedTotalPages,
    unconfirmedCurrentPage,
    unconfirmedUserTime,
    unconfirmedUserDate,
    userId,
  } = state;
  const [dayCheck, setDayCheck] = useState<any>({
    selectedDay: "",
    selectedTimes: [],
    mondayTimes: [],
    tuesdayTimes: [],
    wednesdayTimes: [],
    thursdayTimes: [],
    fridayTimes: [],
    saturdayTimes: [],
    sundayTimes: [],
  });
  const {
    selectedDay,
    selectedTimes,
    mondayTimes,
    tuesdayTimes,
    wednesdayTimes,
    thursdayTimes,
    fridayTimes,
    saturdayTimes,
    sundayTimes,
  } = dayCheck;

  const onchange = (e: any) => {
    if (e.target.checked) {
      setDayCheck({
        ...dayCheck,
        [e.target.name]: e.target.value,
      });
      if (e.target.value === "monday") {
        setDayCheck({
          ...dayCheck,
          selectedTimes: mondayTimes,
          selectedDay: e.target.value,
        });
      }
      if (e.target.value === "tuesday") {
        setDayCheck({
          ...dayCheck,
          selectedTimes: tuesdayTimes,
          selectedDay: e.target.value,
        });
      }
      if (e.target.value === "wednesday") {
        setDayCheck({
          ...dayCheck,
          selectedTimes: wednesdayTimes,
          selectedDay: e.target.value,
        });
      }
      if (e.target.value === "thursday") {
        setDayCheck({
          ...dayCheck,
          selectedTimes: thursdayTimes,
          selectedDay: e.target.value,
        });
      }
      if (e.target.value === "friday") {
        setDayCheck({
          ...dayCheck,
          selectedTimes: fridayTimes,
          selectedDay: e.target.value,
        });
      }
      if (e.target.value === "saturday") {
        setDayCheck({
          ...dayCheck,
          selectedTimes: saturdayTimes,
          selectedDay: e.target.value,
        });
      }
      if (e.target.value === "sunday") {
        setDayCheck({
          ...dayCheck,
          selectedTimes: sundayTimes,
          selectedDay: e.target.value,
        });
      }
    }
  };
  const onchangeTime = (e: any) => {
    if (selectedDay === "") {
      setDayCheck({
        ...dayCheck,
        selectedTimes: [],
      });
      return;
    }
    if (e.target.checked) {
      selectedTimes.push(e.target.value);
      setDayCheck({
        ...dayCheck,
        [e.target.name]: e.target.value,
      });
      if (selectedDay === "monday") {
        setDayCheck({
          ...dayCheck,
          mondayTimes: selectedTimes,
        });
      }
      if (selectedDay === "tuesday") {
        setDayCheck({
          ...dayCheck,
          tuesdayTimes: selectedTimes,
        });
      }
      if (selectedDay === "wednesday") {
        setDayCheck({
          ...dayCheck,
          wednesdayTimes: selectedTimes,
        });
      }
      if (selectedDay === "thursday") {
        setDayCheck({
          ...dayCheck,
          thursdayTimes: selectedTimes,
        });
      }
      if (selectedDay === "friday") {
        setDayCheck({
          ...dayCheck,
          fridayTimes: selectedTimes,
        });
      }
      if (selectedDay === "saturday") {
        setDayCheck({
          ...dayCheck,
          saturdayTimes: selectedTimes,
        });
      }
      if (selectedDay === "sunday") {
        setDayCheck({
          ...dayCheck,
          sundayTimes: selectedTimes,
        });
      }
    }
    if (!e.target.checked) {
      const result: string[] = [];
      selectedTimes.map((eachitem) => {
        if (e.target.value !== eachitem) {
          result.push(eachitem);
        }
      });
      //   setDayCheck({
      //     ...dayCheck,
      //     selectedTimes: [...result],
      //   });

      if (selectedDay === "monday") {
        setDayCheck({
          ...dayCheck,
          mondayTimes: [...result],
          selectedTimes: [...result],
        });
      }
      if (selectedDay === "tuesday") {
        setDayCheck({
          ...dayCheck,
          tuesdayTimes: [...result],
          selectedTimes: [...result],
        });
      }
      if (selectedDay === "wednesday") {
        setDayCheck({
          ...dayCheck,
          wednesdayTimes: [...result],
          selectedTimes: [...result],
        });
      }
      if (selectedDay === "thursday") {
        setDayCheck({
          ...dayCheck,
          thursdayTimes: [...result],
          selectedTimes: [...result],
        });
      }
      if (selectedDay === "friday") {
        setDayCheck({
          ...dayCheck,
          fridayTimes: [...result],
          selectedTimes: [...result],
        });
      }
      if (selectedDay === "saturday") {
        setDayCheck({
          ...dayCheck,
          saturdayTimes: [...result],
          selectedTimes: [...result],
        });
      }
      if (selectedDay === "sunday") {
        setDayCheck({
          ...dayCheck,
          sundayTimes: [...result],
          selectedTimes: [...result],
        });
      }
    }
  };
  const openApprovalModal = () => {
    setState({
      ...state,
      approve: true,
    });
  };
  const closeApprovalModal = () => {
    setState({
      ...state,
      approve: false,
    });
  };
  const openScheduler = () => {
    setState({
      ...state,
      schedule: true,
    });
  };
  const closeScheduler = () => {
    setState({
      ...state,
      schedule: false,
    });
  };
  const openConfirmed = () => {
    setState({
      ...state,
      confirmed: true,
    });
  };
  const openUnconfirmed = () => {
    setState({
      ...state,
      confirmed: false,
    });
  };
  React.useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .all([
        axios.get(`${API}/dashboard/profile`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((response) => {
          if (
            response.status === 200 
          ) {
            //console.log(response)
            setState({
              ...state,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
            });
          }
          updateList();
          // //console.log(unconfirmedList);
        })
      )
      .catch((err) => {
        // //console.log(err);
      });
  }, []);

  const updateList = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .all([
        axios.get(`${API}/admin/appointment/unconfirmed`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/admin/appointment/confirmed`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/dashboard/profile`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          if (
            res1.status === 200 &&
            res2.status === 200 &&
            res3.status === 200
          ) {
            setState({
              ...state,
              unconfirmedList: [...res1.data.results],
              unconfirmedPrev: res1.data.previous,
              unconfirmedNext: res1.data.next,
              unconfirmedTotalPages: res1.data.total_pages,
              unconfirmedCurrentPage: res1.data.page,
              confirmedList: [...res2.data.results],
              confirmedPrev: res2.data.previous,
              confirmedNext: res2.data.next,
              confirmedTotalPages: res2.data.total_pages,
              confirmedCurrentPage: res2.data.page,
              approve: false,
              first_name: res3.data.first_name,
              last_name: res3.data.last_name,
            });
          }
        })
      )
      .catch((err) => {
        // //console.log(err);
      });
  }

  const sendProposedDate = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    const data = {
      days: [
        {
          day: "monday",
          time: mondayTimes,
        },
        {
          day: "tuesday",
          time: tuesdayTimes,
        },
        {
          day: "wednesday",
          time: wednesdayTimes,
        },
        {
          day: "thursday",
          time: thursdayTimes,
        },
        {
          day: "friday",
          time: fridayTimes,
        },
        {
          day: "saturday",
          time: saturdayTimes,
        },
        {
          day: "sunday",
          time: sundayTimes,
        },
      ],
    };
    // //console.log(data)
    axios
      .all([
        axios.post(`${API}/admin/schedule-preference/`, data, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((response) => {
          if (response.status === 200) {
            notify("Schedule preferences set!");
          }
        })
      )
      .catch((error) => {
        // //console.log(error.response);
        if (error) {
          notify("Failed to send, try again");
        }
      });
  };
  const confirmedLoadOldData = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .get(`${confirmedPrev}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        // //console.log(res);
        setState({
          ...state,
          confirmedList: [...res.data.results],
          confirmedNext: res.data.next,
          confirmedPrev: res.data.previous,
          confirmedTotalPages: res.data.total_pages,
          confirmedCurrentPage: res.data.page,
        });
        // //console.log(res);
      })
      .catch((err) => {
        // //console.log(err);
      });
  };
  const confirmedLoadNewData = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .get(`${confirmedNext}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        // //console.log(res);
        setState({
          ...state,
          confirmedList: [...res.data.results],
          confirmedNext: res.data.next,
          confirmedPrev: res.data.previous,
          confirmedTotalPages: res.data.total_pages,
          confirmedCurrentPage: res.data.page,
        });
        // //console.log(res);
      })
      .catch((err) => {
        // //console.log(err);
      });
  };
  const unconfirmedLoadOldData = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .get(`${confirmedPrev}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        // //console.log(res);
        setState({
          ...state,
          unconfirmedList: [...res.data.results],
          unconfirmedNext: res.data.next,
          unconfirmedPrev: res.data.previous,
          unconfirmedTotalPages: res.data.total_pages,
          unconfirmedCurrentPage: res.data.page,
        });
        // //console.log(res);
      })
      .catch((err) => {
        // //console.log(err);
      });
  };
  const unconfirmedLoadNewData = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .get(`${unconfirmedNext}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        // //console.log(res);
        setState({
          ...state,
          unconfirmedList: [...res.data.results],
          unconfirmedNext: res.data.next,
          unconfirmedPrev: res.data.previous,
          unconfirmedTotalPages: res.data.total_pages,
          unconfirmedCurrentPage: res.data.page,
        });
        // //console.log(res);
      })
      .catch((err) => {
        // //console.log(err);
      });
  };
  const approveAppointment = (data) => {
    //console.log(`get/id/${data.id}`)
     const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .all([
        axios.get(`${API}/admin/confirm-appointment/${data.id}`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res2) => {
          if (res2.status === 200) {
            setState({
              ...state,
              userId: data.id,
              emailText: res2.data.text,
              unconfirmedUserTime: data.time,
              unconfirmedUserDate: data.date,
              approve: true,
            });
          }
        })
      )
      .catch((error) => {});
  }
  const onChangeEmailText = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      emailText: e.target.value,
    });
    // //console.log(emailText);
  };
  const confirmApproval = () => {
    //console.log(`post/id/${userId}`)
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    const data = {
      text: emailText,
    };
    axios
      .all([
        axios.post(`${API}/admin/confirm-appointment/${userId}/`, data, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((response) => {
          if (response.status === 200) {
            // closeApprovalModal();
            notify("Appointment approval successful!");
            // setState({
            //   ...state,
            //   approve: false,
            // });
            updateList();
          }
        })
      )
      .catch((error) => {
        // //console.log(error.response);
        if (error) {
          notify("Appointment approval failed! Try again later");
        }
      });
  };
  const notify = (message: string) => {
    window.scrollTo(-0, -0);
    toast(message, { containerId: "B" });
  };
  const viewInterviewer = () => {
    return window.location.assign("/admin/interviewer");
  }
  return (
    <>
      <Container fluid={true} className="contann122">
        <AdminNavBar appoint={true} />
        <Row>
          <AdminSideBar appoint={true} />
          <Col md={10} sm={12} className="prm apptbg newprm">
            <DashboardLargeScreenNav title="All Appointments" />
            <Row>
              <Col md={12} className="kisls">
                <div className="kdashheader npps">
                  <div className="fjss newfjss">
                    <div>
                      {" "}
                      <span className="kdashheaderlight idds">
                        Hi{" "}
                        <span className="ksname">
                          {first_name ? first_name + " " + last_name : ""}
                        </span>
                      </span>
                    </div>
                    <button className="addinterviewer" onClick={viewInterviewer}>View Interviewers</button>
                  </div>
                </div>
                <div className="messageerit findbelow apptmanage">
                  Manage all your Appointments and schedules
                </div>
                <div className="apptsec1">
                  <button className="apptbtn" onClick={openScheduler}>
                    Set Schedule Preference
                  </button>
                  <div className="appttxt1">
                    You can set a pool of days and time which are free for
                    clients to select from
                  </div>
                </div>
                <div className="apptlistmarg">
                  <div className="apptswitch">
                    <div
                      className={
                        confirmed ? "apptconf apptselected" : "apptconf"
                      }
                      onClick={openConfirmed}
                    >
                      Confirmed Appointments
                    </div>
                    <div
                      className={
                        !confirmed ? "apptunconf apptselected" : "apptunconf"
                      }
                      onClick={openUnconfirmed}
                    >
                      Unconfirmed Appointments
                    </div>
                  </div>
                  {confirmed && (
                    <>
                      <div className="apptlist">
                        <div className="apptlistttl">
                          List of all your Confirmed Appointments
                        </div>
                        <div className="apptlisthead">
                          <div className="apptname">Client’s Name</div>
                          <div className="apptdate">Appointment Date/Time</div>
                        </div>

                        {confirmedList.length > 0 &&
                          confirmedList.map((x, i) => (
                            <div key={i}>
                              <div className="apptlist1">
                                <div className="apptname">{x.name}</div>
                                <div className="apptdate">
                                  {x.date}
                                  <span className="appttime">{x.time}</span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {confirmedList.length > 0 && (
                        <div className="next_page apptpage">
                          <div>
                            Displaying{" "}
                            <span className="page_num">
                              {confirmedCurrentPage}
                            </span>{" "}
                            out of{" "}
                            <span className="page_num">
                              {confirmedTotalPages}
                            </span>
                          </div>
                          <div>
                            {confirmedPrev && (
                              <img
                                className="page_change"
                                src={prevpage}
                                onClick={confirmedLoadOldData}
                                alt="previous page"
                              />
                            )}
                            {confirmedNext && (
                              <img
                                onClick={confirmedLoadNewData}
                                className="page_change"
                                src={nextImg}
                                alt="next page"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {!confirmed && (
                    <>
                      <div className="apptlist">
                        <div className="apptlistttl">
                          List of all your Unconfirmed Appointments
                        </div>
                        <div className="apptlisthead">
                          <div className="apptname">Client’s Name</div>
                          <div className="apptdate">Appointment Date/Time</div>
                        </div>
                        {unconfirmedList.length > 0 &&
                          unconfirmedList.map((x, i) => (
                            <div key={i}>
                              <div className="apptlist1">
                                <div className="apptname1">{x.name}</div>
                                <div className="apptdate2">
                                  {x.date}
                                  <span className="appttime">{x.time}</span>
                                </div>
                                <div className="apptapprove">
                                  <button
                                    className="approvebtn"
                                    onClick={() => {
                                      approveAppointment(x);
                                    }}
                                  >
                                    Approve
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>

                      {unconfirmedList.length > 0 && (
                        <div className="next_page apptpage">
                          <div>
                            Displaying{" "}
                            <span className="page_num">
                              {unconfirmedCurrentPage}
                            </span>{" "}
                            out of{" "}
                            <span className="page_num">
                              {unconfirmedTotalPages}
                            </span>
                          </div>
                          <div>
                            {unconfirmedPrev && (
                              <img
                                className="page_change"
                                src={prevpage}
                                onClick={unconfirmedLoadOldData}
                                alt="previous page"
                              />
                            )}
                            {unconfirmedNext && (
                              <img
                                onClick={unconfirmedLoadNewData}
                                className="page_change"
                                src={nextImg}
                                alt="next page"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ToastContainer
        enableMultiContainer
        containerId={"B"}
        toastClassName="bg-info text-white"
        hideProgressBar={true}
        position={toast.POSITION.TOP_CENTER}
      />
      <Modal
        show={schedule}
        className="scheduler"
        onHide={closeScheduler}
        centered
      >
        <Modal.Header className="schhead">
          <img
            onClick={closeScheduler}
            className="closesched"
            src={close}
            alt="close"
          />
          <Modal.Title className="schttl">
            Schedule an Appointment {selectedDay}
          </Modal.Title>
          <div className="schtxt1">
            Select a suitable day and time for your client appointments
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="schpref">
            <div>
              <div className="slctttl">Pick a date from the list</div>
              <div className="dayslct">
                <label htmlFor="monday" className="dayslct1">
                  <input
                    className="selection"
                    id="monday"
                    type="radio"
                    name="day"
                    value="monday"
                    onChange={onchange}
                  />{" "}
                  <span className="daytxt">MONDAY</span>
                </label>
                <label htmlFor="tuesday" className="dayslct2">
                  <input
                    className="selection"
                    id="tuesday"
                    type="radio"
                    name="day"
                    value="tuesday"
                    onChange={onchange}
                  />{" "}
                  <span className="daytxt">TUESDAY</span>
                </label>
                <label htmlFor="wednesday" className="dayslct2">
                  <input
                    className="selection"
                    id="wednesday"
                    type="radio"
                    name="day"
                    value="wednesday"
                    onChange={onchange}
                  />{" "}
                  <span className="daytxt">WEDNESDAY</span>
                </label>
                <label htmlFor="thursday" className="dayslct2">
                  <input
                    className="selection"
                    id="thursday"
                    type="radio"
                    name="day"
                    value="thursday"
                    onChange={onchange}
                  />{" "}
                  <span className="daytxt">THURSDAY</span>
                </label>
                <label htmlFor="friday" className="dayslct2">
                  <input
                    className="selection"
                    id="friday"
                    type="radio"
                    name="day"
                    value="friday"
                    onChange={onchange}
                  />{" "}
                  <span className="daytxt">FRIDAY</span>
                </label>
                <label htmlFor="saturday" className="dayslct2">
                  <input
                    className="selection"
                    id="saturday"
                    type="radio"
                    name="day"
                    value="saturday"
                    onChange={onchange}
                  />{" "}
                  <span className="daytxt">SATURDAY</span>
                </label>
                <label htmlFor="sunday" className="dayslct3">
                  <input
                    className="selection"
                    id="sunday"
                    type="radio"
                    name="day"
                    value="sunday"
                    onChange={onchange}
                  />{" "}
                  <span className="daytxt">SUNDAY</span>
                </label>
              </div>
            </div>
            <div className="timeslct">
              <div className="slctttl">Select time of day</div>
              <div className="dayslct">
                <label htmlFor="time1" className="dayslct1">
                  <input
                    className="selection"
                    id="time1"
                    type="checkbox"
                    name="time"
                    value="09:00 am - 10:00am"
                    checked={selectedTimes.indexOf("09:00 am - 10:00am") >= 0}
                    onChange={onchangeTime}
                  />{" "}
                  <span className="daytxt">09:00 am - 10:00am</span>
                </label>
                <label htmlFor="time2" className="dayslct2">
                  <input
                    className="selection"
                    id="time2"
                    type="checkbox"
                    name="time"
                    value="10:00 am - 11:00am"
                    checked={selectedTimes.indexOf("10:00 am - 11:00am") >= 0}
                    onChange={onchangeTime}
                  />{" "}
                  <span className="daytxt">10:00 am - 11:00am</span>
                </label>
                <label htmlFor="time3" className="dayslct2">
                  <input
                    className="selection"
                    id="time3"
                    type="checkbox"
                    name="time"
                    value="11:00 am - 12:00pm"
                    checked={selectedTimes.indexOf("11:00 am - 12:00pm") >= 0}
                    onChange={onchangeTime}
                  />{" "}
                  <span className="daytxt">11:00 am - 12:00pm</span>
                </label>
                <label htmlFor="time4" className="dayslct2">
                  <input
                    className="selection"
                    id="time4"
                    type="checkbox"
                    name="time"
                    value="12:00 pm - 01:00pm"
                    checked={selectedTimes.indexOf("12:00 pm - 01:00pm") >= 0}
                    onChange={onchangeTime}
                  />{" "}
                  <span className="daytxt">12:00 pm - 01:00pm</span>
                </label>
                <label htmlFor="time5" className="dayslct2">
                  <input
                    className="selection"
                    id="time5"
                    type="checkbox"
                    name="time"
                    value="01:00 pm - 02:00pm"
                    checked={selectedTimes.indexOf("01:00 pm - 02:00pm") >= 0}
                    onChange={onchangeTime}
                  />{" "}
                  <span className="daytxt">01:00 pm - 02:00pm</span>
                </label>
                <label htmlFor="time6" className="dayslct2">
                  <input
                    className="selection"
                    id="time6"
                    type="checkbox"
                    name="time"
                    value="02:00 pm - 03:00pm"
                    checked={selectedTimes.indexOf("02:00 pm - 03:00pm") >= 0}
                    onChange={onchangeTime}
                  />{" "}
                  <span className="daytxt">02:00 pm - 03:00pm</span>
                </label>
                <label htmlFor="time7" className="dayslct2">
                  <input
                    className="selection"
                    id="time7"
                    type="checkbox"
                    name="time"
                    value="03:00 pm - 04:00pm"
                    checked={selectedTimes.indexOf("03:00 pm - 04:00pm") >= 0}
                    onChange={onchangeTime}
                  />{" "}
                  <span className="daytxt">03:00 pm - 04:00pm</span>
                </label>
                <label htmlFor="time8" className="dayslct3">
                  <input
                    className="selection"
                    id="time8"
                    type="checkbox"
                    name="time"
                    value="04:00 am - 05:00pm"
                    checked={selectedTimes.indexOf("04:00 am - 05:00pm") >= 0}
                    onChange={onchangeTime}
                  />{" "}
                  <span className="daytxt">04:00 am - 05:00pm</span>
                </label>
              </div>
            </div>
          </div>
          <div className="sendbtnsec">
            <button className="senddate" onClick={sendProposedDate}>
              Send Proposed Date
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="approve"
        show={approve}
        onHide={closeApprovalModal}
        centered
      >
        <Modal.Header>
          <img
            onClick={closeApprovalModal}
            className="closesched"
            src={close}
            alt="close"
          />
          <Modal.Title>Confirm Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="apprvsec">
            <input
              className="apprvinput"
              type="text"
              placeholder="--Enter Text--"
              value={emailText}
              name="newtext"
              onChange={onChangeEmailText}
            />
            <div className="apprvtxt">
              This Appointment has been set for{" "}
              <span className="apprvsubtxt">{unconfirmedUserDate}</span> at{" "}
              <span className="apprvsubtxt">{unconfirmedUserTime}</span>.
            </div>
          </div>
          <div className="apprvtxt2">
            An Email will be sent to this client for this Appointment
          </div>
          <div className="apprvvvbtnn">
            <button className="apprvbtn" onClick={confirmApproval}>
              Confirm & Send
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminAppointments;
