import * as React from "react";
import Navbar from "../HomeComponents/newnavbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Footer from "../HomeComponents/newfooter";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import "./signup.css";
import SignupImage from "../../../assets/logins.png";
import fb from "../../../assets/fbsignup.png";
import google from "../../../assets/google.png";
import linkedin from "../../../assets/linked.png";
import { Link } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { API } from "../../../config";
import GoogleLogin from "react-google-login";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import comingsoon from "../../../assets/comingsoon.jpg";
import { event } from "react-ga";

interface State {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
  whereDidYouLearnAboutUs: string;
  errorMessage: string;
  successMsg: boolean;
  isLoading: boolean;
  signupasOrganization: boolean;
  signupasindividual: boolean;
  isOpen: boolean;
}
const SignUp: React.FunctionComponent = (props: any) => {
  const [state, setFormState] = React.useState<State>({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
    whereDidYouLearnAboutUs: "",
    signupasOrganization: false,
    signupasindividual: true,
    errorMessage: "",
    successMsg: false,
    isLoading: false,
    isOpen: true,
  });
  const {
    firstname,
    lastname,
    email,
    password,
    confirmPassword,
    whereDidYouLearnAboutUs,
    errorMessage,
    successMsg,
    signupasOrganization,
    signupasindividual,
    isLoading,
  } = state;

  const sendMemberFormData = () => {
    setFormState({ ...state, isLoading: true });
    const data = {
      first_name: firstname,
      last_name: lastname,
      email,
      password,
      password2: confirmPassword,
      thirdparty: "",
      type: signupasOrganization ? "organization" : "individual",
    };
    //console.log(data);
    axios
      .post<any, AxiosResponse<any>>(`${API}/accounts/signupthirdparty/`, data)
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          setFormState({
            ...state,
            successMsg: response.data[0].message,
            isLoading: false,
          });
          localStorage.setItem(
            "userToken",
            JSON.stringify(response?.data[0]?.token)
          );
          getUserInfo(response.data[0].token);
          checkeritprogress(response.data[0].token);
        }
      })
      .catch((error) => {
        window.scrollTo(-0, -0);
        //console.log(error.response);
        if (error && error.response && error.response.data) {
          return setFormState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        setFormState({
          ...state,
          errorMessage: "Signup failed",
          isLoading: false,
        });
      });
  };
  const sendManagerFormData = () => {
    setFormState({ ...state, isLoading: true });
    const data = {
      first_name: firstname,
      last_name: lastname,
      email,
      password,
      password2: confirmPassword,
      thirdparty: "erit",
      type: signupasOrganization ? "organization" : "individual",
    };
    //console.log(data);
    axios
      .post<any, AxiosResponse<any>>(`${API}/accounts/admin-signup/`, data)
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          setFormState({
            ...state,
            successMsg: response.data[0].message,
            isLoading: false,
          });
          localStorage.setItem(
            "userToken",
            JSON.stringify(response?.data[0]?.token)
          );
          getUserInfo(response.data[0].token);
          return window.location.assign("/adminoverview");
        }
      })
      .catch((error) => {
        //console.log(error.response);
        if (error && error.response && error.response.data) {
          return setFormState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        setFormState({
          ...state,
          errorMessage: "Signup failed",
          isLoading: false,
        });
      });
  };
  // const closeModal = () => {
  //   setFormState({
  //     ...state,
  //     signupasOrganization: false,
  //     signupasindividual: true,
  //   });
  // };
  const validateFormMember: any = (e) => {
    e.preventDefault();
    if (firstname == "") {
      return setFormState({
        ...state,
        errorMessage: "Please enter your first name",
      });
    }
    if (lastname == "") {
      return setFormState({
        ...state,
        errorMessage: "Please enter your lastname",
      });
    }

    if (email == "") {
      return setFormState({
        ...state,
        errorMessage: "Please enter your email",
      });
    }
    if (password == "") {
      return setFormState({
        ...state,
        errorMessage: "Please enter your password",
      });
    }
    if (password && email) {
        // console.log("sign up as member")
        return sendMemberFormData();
    }
  };
  const validateFormManager: any = (e) => {
    e.preventDefault();
    if (firstname == "") {
      return setFormState({
        ...state,
        errorMessage: "Please enter your first name",
      });
    }
    if (lastname == "") {
      return setFormState({
        ...state,
        errorMessage: "Please enter your lastname",
      });
    }

    if (email == "") {
      return setFormState({
        ...state,
        errorMessage: "Please enter your email",
      });
    }
    if (password == "") {
      return setFormState({
        ...state,
        errorMessage: "Please enter your password",
      });
    }
    if (password && email) {
        // console.log("sign up as manager")
        return sendManagerFormData();
    }
  };
  const changeActionOnFormData = (e: any) => {
    setFormState({
      ...state,
      [e.target.name]: e.target.value,
      errorMessage: "",
      successMsg: false,
    });
  };
  const responseGoogle = (response) => {
    //console.log(response);
    //console.log(response.profileObj);
    const data = {
      name: response.profileObj.name,
      email: response.profileObj.email,
      user_id: response.googleId,
      imageUrl: response.profileObj.imageUrl,
      provider: "Google",
    };
    //console.log(data);
    axios
      .post(`${API}/accounts/socialauth/`, data)
      .then((response) => {
        //console.log(response?.data[0]?.token);
        localStorage.setItem(
          "userToken",
          JSON.stringify(response?.data[0]?.token)
        );
        checkeritprogress(response?.data[0]?.token);
      })
      .catch((error) => {
        //console.log(error);
        setFormState({
          ...state,
          errorMessage: "Operation failed server error",
        });
      });
  };
  const getUserInfo = (token: string): any => {
    axios
      .get(`${API}/currentuser`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response?.data));
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const checkeritprogress = (token: string) => {
    axios
      .get(`${API}/erit-progress`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        //console.log(response);
        if (
          response.data[0].payment === false &&
          response.data[0].onboarding === true
        ) {
          return props.history.push("/overview");
        }
        if (
          response.data[0].payment === false &&
          response.data[0].onboarding === true
        ) {
          return props.history.push("/paymentsummary");
        }
        if (
          response.data[0].payment === false &&
          response.data[0].onboarding === false
        ) {
          return props.history.push("/individualonboarding");
        }
      })
      .catch((err) => {
        //console.log(err);
        setFormState({
          ...state,
          errorMessage: "Failed to Login",
          isLoading: false,
        });
      });
  };

  const errorGoogle = (response) => {
    setFormState({
      ...state,
      errorMessage: "",
    });
  };
  const getCurrentAssessmentPosition = (token: string): void => {
    axios
      .get(`${API}/progress`, { headers: { Authorization: `Token ${token}` } })
      .then((response) => {
        //console.log(response);
        if (
          (response.status === 200 &&
            response.data[0].next === "phase_four_sports") ||
          response.data[0].next === "phase_four_business" ||
          response.data[0].next === "phase_four_stem"
        ) {
          return props.history.push(`/assessmentphasefour1`);
        }
        if (response.status === 200 && response.data[0].next === "phase_one") {
          return props.history.push(`/assessmentphaseone`);
        }
        if (response.status === 200 && response.data[0].next === "phase_two") {
          return props.history.push(`/assessmentphasetwo`);
        }
        if (
          response.status === 200 &&
          response.data[0].next === "phase_three"
        ) {
          return props.history.push(`/assessmentphasethree`);
        }
        if (response.status === 200 && response.data[0].next === "phase_five") {
          return props.history.push(`/assessmentphasefive`);
        }
        if (response.status === 200 && response.data[0].next === "phase_six") {
          return props.history.push(`/assessmentphasesix`);
        }
        if (
          response.status === 200 &&
          response.data[0].next === "phase_seven"
        ) {
          return props.history.push(`/assessmentphaseseven`);
        }
        if (response.status === 200 && response.data[0].next === "home") {
          return props.history.push(`/dashboard/personality`);
        }
      })
      .catch((error) => {
        //console.log(error.response);
        setFormState({
          ...state,
          errorMessage: error?.response?.data?.detail,
        });
      });
  };
  useEffect(() => {
    window.scrollTo(-0, -0);
  }, []);
  return (
    <>
      <Navbar />
      <Container className="c4csignup" fluid={true}>
        <Row className="kli">
          {/* <Col md={5} className="mo">
            <img
              src={SignupImage}
              className="signupimg img-fluid"
              alt="SignupImage"
            />
          </Col> */}
          <Col>
            <div className="signwa navv signin-heading">
              Sign up to get a Clarity for Communities Account
            </div>
            <br />
            <div className="selectcat">
              <div
                className={
                  signupasindividual ? " cat2signup OrgIsActive" : "cat2signup"
                }
                onClick={() => {
                  setFormState({
                    ...state,
                    signupasindividual: true,
                    signupasOrganization: false,
                    firstname: "",
                    lastname: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                    whereDidYouLearnAboutUs: "",
                  });
                }}
              >
                Community Member
              </div>
              <div
                className={
                  signupasOrganization
                    ? " cat1signup OrgIsActive"
                    : "cat1signup"
                }
                onClick={() => {
                  setFormState({
                    ...state,
                    signupasindividual: false,
                    signupasOrganization: true,
                    firstname: "",
                    lastname: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                    whereDidYouLearnAboutUs: "",
                  });
                }}
              >
                Community Manager
              </div>
            </div>
            <br />
            {signupasindividual && (
              <>
                <div className="signwa1">Create a Community Member Account</div>
                {successMsg && (
                  <Alert key={1} variant="info">
                    {successMsg}
                  </Alert>
                )}
                {errorMessage && (
                  <Alert key={2} variant="danger">
                    {errorMessage}
                  </Alert>
                )}
                <div className="enter">Enter Details</div>
                <Form onSubmit={validateFormMember}>
                  <Row>
                    <Col>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Control
                          className="field1"
                          value={firstname}
                          onChange={changeActionOnFormData}
                          name="firstname"
                          placeholder="First Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Control
                          className="field1"
                          name="lastname"
                          value={lastname}
                          onChange={changeActionOnFormData}
                          placeholder="Last Name"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      className="field1"
                      value={email}
                      name="email"
                      onChange={changeActionOnFormData}
                      placeholder="Email Address"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      className="field1"
                      value={password}
                      name="password"
                      onChange={changeActionOnFormData}
                      placeholder="Password"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      className="field1"
                      value={confirmPassword}
                      name="confirmPassword"
                      onChange={changeActionOnFormData}
                      placeholder="Confirm Password"
                    />
                  </Form.Group>
                  <Button variant="primary" className="subbtn" type="submit">
                    {!isLoading ? "Sign Up" : "Signing Up..."}
                  </Button>
                  <div className="alreadyhave">
                    Already have an account?
                    <Link to="/signin">
                      <span className="logn"> Login</span>
                    </Link>
                  </div>
                  <h6 className="text-divider">
                    <span className="divider-text">or connect using</span>
                    <div className="centeredline"></div>
                  </h6>
                  <div className="socialwrapper">
                    {/* <div className="socialIcons1">
                  <img src={fb} alt="fb" />
                </div> */}
                    <GoogleLogin
                      clientId="53707797583-8rbiv5j6gdac35ik840rtcc65pklp9e9.apps.googleusercontent.com"
                      render={(renderProps) => (
                        <div className="socialIcons2">
                          <img
                            src={google}
                            className="gooogg"
                            onClick={renderProps.onClick}
                            alt="fb"
                          />
                        </div>
                      )}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onFailure={errorGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                    {/* <div className="socialIcons3">
                  <img src={linkedin} alt="fb" />
                </div> */}
                  </div>
                </Form>
              </>
            )}
            {signupasOrganization && (
              <>
                <Col md={12}>
                  <div className="signwa1">
                    Create a Community Manager Account
                  </div>
                  {successMsg && (
                    <Alert key={1} variant="info">
                      {successMsg}
                    </Alert>
                  )}
                  {errorMessage && (
                    <Alert key={2} variant="danger">
                      {errorMessage}
                    </Alert>
                  )}
                  <div className="enter">Enter Details</div>
                  <Form onSubmit={validateFormManager}>
                    <Row>
                      <Col>
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Control
                            className="field1"
                            value={firstname}
                            onChange={changeActionOnFormData}
                            name="firstname"
                            placeholder="First Name"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Control
                            className="field1"
                            name="lastname"
                            value={lastname}
                            onChange={changeActionOnFormData}
                            placeholder="Last Name"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        className="field1"
                        value={email}
                        name="email"
                        onChange={changeActionOnFormData}
                        placeholder="Email Address"
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="password"
                        className="field1"
                        value={password}
                        name="password"
                        onChange={changeActionOnFormData}
                        placeholder="Password"
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="password"
                        className="field1"
                        value={confirmPassword}
                        name="confirmPassword"
                        onChange={changeActionOnFormData}
                        placeholder="Confirm Password"
                      />
                    </Form.Group>
                    <Button variant="primary" className="subbtn" type="submit">
                      {!isLoading ? "Sign Up" : "Signing Up..."}
                    </Button>
                    <div className="alreadyhave">
                      Already have an account?
                      <Link to="/signin">
                        <span className="logn"> Login</span>
                      </Link>
                    </div>
                    {/* <h6 className="text-divider">
                <span className="divider-text">or connect using</span>
                <div className="centeredline"></div>
              </h6> */}
                    <div className="socialwrapper">
                      {/* <div className="socialIcons1">
                  <img src={fb} alt="fb" />
                </div> */}
                      {/* <GoogleLogin
                  clientId="53707797583-8rbiv5j6gdac35ik840rtcc65pklp9e9.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <div className="socialIcons2">
                      <img
                        src={google}
                        className="gooogg"
                        onClick={renderProps.onClick}
                        alt="fb"
                      />
                    </div>
                  )}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={errorGoogle}
                  cookiePolicy={"single_host_origin"}
                /> */}
                      {/* <div className="socialIcons3">
                  <img src={linkedin} alt="fb" />
                </div> */}
                    </div>
                  </Form>
                </Col>
              </>
            )}
          </Col>
        </Row>
        {/* <Footer /> */}
      </Container>
      {/* <Modal show={signupasOrganization} className="coming" centered={true} onHide={closeModal}>
        <Modal.Body>
          <img src={comingsoon} className="comingsoon" alt="comingsoon"/>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default SignUp;
