import * as React from "react";
import SideNav from "react-simple-sidenav";
import Row from "react-bootstrap/Row";
import imgCart from "../../../assets/Claritylogo.png";
import Col from "react-bootstrap/Col";
import activeinsight from "../../../assets/insights_active.png";
import Overview_inactive from "../../../assets/Overview_inactive.png";
import chatinactive from "../../../assets/Chat with a counsellor_inactive.png";
import chatactive from "../../../assets/Chat with a counsellor_active.png";
import inactiveinsight from "../../../assets/inactiveinsight.png";
import recommedationactive from "../../../assets/Counsellors recommendation_active.png";
import recommedationinactive from "../../../assets/Counsellors recommendation_inactive.png";
import jobrecommedationactive from "../../../assets/Job recommendation_active.png";
import jobrecommedationinactive from "../../../assets/Job recommendation_inactive.png";
import settingsactive from "../../../assets/settings.png";
import settingsinactive from "../../../assets/Settings_inactive.png";
import subscriptionactive from "../../../assets/Subcription_active.png";
import subscriptioninactive from "../../../assets/Subscription_inactive.png";
import profilebuilder from "../../../assets/Profile builder_active.png";
import profilebuilderinactive from "../../../assets/Profile builder_inactive.png";
import support from "../../../assets/Support_active.png";
import supportinactive from "../../../assets/Support_inactive.png";
import overview from "../../../assets/overview.png";
import logout from "../../../assets/log-out.png";
import "../../Home/Home/Home.css";
import { Link } from "react-router-dom";
import Axios, { AxiosResponse } from "axios";
import { API } from "../../../config";
import { Modal } from "react-bootstrap";
import exit from "../../../assets/exit.png";
import "../../Dashboard/dashboard.css";
import watchactive from "../../../assets/watchactive.png"
import watchinactive from "../../../assets/watchinactive.png"

const AdminNavBar = (props: any) => {
  const [user, setNewState] = React.useState("");
  const [showNav, setShowNav]: any = React.useState(false);
  const [modState, setModState] = React.useState({
    logoutModal: false,
  });
  const { logoutModal } = modState;
  const closeLogoutModal = () => {
    setModState({
      ...modState,
      logoutModal: false,
    });
  };
  const openLogoutModal = () => {
    setModState({
      ...modState,
      logoutModal: true,
    });
  };
  const logOutMobile = (e) => {
    e.preventDefault();
    const details: any = localStorage.getItem("userDetails");
    const info = JSON.parse(details);
    let token = info.token;
    Axios.get(`${API}/api/v1/none`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        window.location.pathname = "/";
        localStorage.clear();
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const logOut = () => {
    localStorage.clear();
    window.location.assign("/signin");
  };
  return (
    <div>
      <Row>
        <div className="hnav">
          <img src={imgCart} className="imgCart" alt="imgCart" />
          <div className="hamburger" onClick={() => setShowNav(true)}>
            <div className="line2a"></div>
            <div className="line2a"></div>
            <div className="line2a"></div>
          </div>
        </div>
      </Row>
      <SideNav
        showNav={showNav}
        style={{ background: showNav ? "rgba(0, 0, 0, 0.7)" : "none" }}
        navStyle={{
          width: "70%",
          background: "#001833",
          padding: 0,
          margin: "0px",
        }}
        onHideNav={() => setShowNav(false)}
        openFromRight={true}
        titleStyle={{
          backgroundColor: "#fff",
          color: "#444444",
          paddingLeft: 0,
          paddingBottom: 0,
          paddingTop: 0,
          fontSize: 17,
          textAlign: "left",
        }}
        title={[,]}
        itemStyle={{
          backgroundColor: "#fff",
          padding: 0,
          margin: "0px",
          textAlign: "left",
        }}
        items={[
          <div className={"siddlemobile"}>
            <div className={"navitem1 fixed-mobilenav ffnewsize"}>
              <Link to={"/adminoverview"}>
                <div className={props.overview ? "activegb" : "gbn"}>
                  <img
                    src={props.overview ? overview : Overview_inactive}
                    className="sideimage"
                    alt="sideimage"
                  />{" "}
                  Overview
                </div>
              </Link>
              <Link to="/adminteammembers">
                <div className={props.teammembers ? "activegb" : "gbn"}>
                  <img
                    src={
                      props.teammembers
                        ? jobrecommedationactive
                        : jobrecommedationinactive
                    }
                    className="sideimage"
                    alt="sideimage"
                  />
                  Team Members
                </div>
              </Link>
              <Link to="/resource_center">
                <div className={props.resource ? "activegb" : "gbn"}>
                  {" "}
                  <img
                    src={
                      props.resource ? subscriptionactive : subscriptioninactive
                    }
                    className="sideimage"
                    alt="sideimage"
                  />
                  Resource center
                </div>
              </Link>
              <Link to="/appointments">
                <div className={props.appoint ? "activegb" : "gbn"}>
                  {" "}
                  <img
                    src={
                      props.appoint ? watchactive : watchinactive
                    }
                    className="sideimage"
                    alt="sideimage"
                  />
                  All Appointments
                </div>
              </Link>
              {/* <Link to="/commingsoon">
                <div
                  //onClick={checkIfUserHasMadePaymentForFullResult}
                  className={props.insight ? "activegb" : "gbn"}
                >
                  {" "}
                  <img
                    src={props.insight ? activeinsight : inactiveinsight}
                    className="sideimage"
                    alt="sideimage"
                  />
                  Talent Insight
                </div>
              </Link>
              <Link to="/commingsoon">
                <div className={props.talentbucket ? "activegb" : "gbn"}>
                  {" "}
                  <img
                    src={props.talentbucket ? settingsactive : settingsinactive}
                    className="sideimage"
                    alt="sideimage"
                  />
                  Talent Bucket
                </div>
              </Link> */}
              <Link to="/commingsoon">
                <div className={props.councrec ? "activegb" : "gbn"}>
                  <img
                    src={
                      props.councrec
                        ? recommedationactive
                        : recommedationinactive
                    }
                    className="sideimage"
                    alt="sideimage"
                  />
                  Members Recommendation
                </div>
              </Link>
              {/* <Link to="/commingsoon">
                <div className={props.chat ? "activegb" : "gbn"}>
                  
                  <img
                    src={props.chat ? chatactive : chatinactive}
                    className="sideimage"
                    alt="sideimage"
                  />
                  Messages
                </div>
              </Link> */}
              {/* <div className={props.Talent ? "activegb" : "gbn"}>
                {" "}
                <Link to="/commingsoon">
                  <img
                    src={props.Talent ? settingsactive : settingsinactive}
                    className="sideimage"
                    alt="sideimage"
                  />
                  Onboarded Members
                </Link>
              </div> */}

              {/* <div className={props.builder ? "activegb" : "gbn"}>
            {" "}
            <Link to="/profilebuilder">
              <img
                src={props.builder ? profilebuilder : profilebuilderinactive}
                className="sideimage"
                alt="sideimage"
              />
              Profile Builder
            </Link>
          </div> */}
              {/* <div className="divide_thro"></div>
              <Link to="/commingsoon">
                <div className={props.settings ? "activegb" : "gbn"}>
                  <img
                    src={props.settings ? settingsactive : settingsinactive}
                    className="sideimage"
                    alt="sideimage"
                  />
                  Settings
                </div>
              </Link> */}
              <div className="divide_thro"></div>
              <div className={props.subscription ? "activegb" : "gbn"}>
                {" "}
                <Link to="/admin/subscription">
                  <img
                    src={
                      props.subscription
                        ? subscriptionactive
                        : subscriptioninactive
                    }
                    className="sideimage"
                    alt="sideimage"
                  />
                  Subscription
                </Link>
              </div>
              <Link to="/admin/support">
                <div className={props.support ? "activegb" : "gbn"}>
                  {" "}
                  <img
                    src={props.support ? support : supportinactive}
                    className="sideimage"
                    alt="sideimage"
                  />
                  Support
                </div>
              </Link>
              <div className={"gbn"} onClick={openLogoutModal}>
                {" "}
                <span>
                  <img src={logout} className="sideimage" alt="sideimage" />
                  Logout
                </span>
              </div>
            </div>
          </div>,
        ]}
      />
      <Modal show={logoutModal} onHide={closeLogoutModal} centered>
        <Modal.Body>
          <img className="exiticon" src={exit} alt="exit icon" />
          <div className="exittxt">
            Are you sure you want to <span className="exittxtsub">LogOut?</span>
          </div>
          <div className="exitbtns">
            <button className="exitbtn1" onClick={closeLogoutModal}>
              Go Back
            </button>
            <button className="exitbtn2" onClick={logOut}>
              Log Out
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AdminNavBar;
