import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../kegennidashboard.css";
import DashboardLargeScreenNav from "../DashboardLargeScreenNav";
import { Link, withRouter } from "react-router-dom";
import AdminSideBar from "./AdminSideBar";
import AdminNavBar from "./AdminNavBar";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../config";
import ImageUploader from "react-images-upload";
import professional4 from "../../../assets/professional4.png";
import downloadicon from "../../../assets/download.png";
import copy from "../../../assets/copy.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./adminModal.css";
import { stringify } from "querystring";

const AdminCreateResourceCenter = withRouter((props: any) => {
  const [state, setState] = useState<any>({
    isOpen: false,
    isOpen2: false,
    isOpen3: false,
    labels: [],
    isloading: false,
    secondloading: false,
    nextLink: "",
    dropdwn: false,
    prevLink: "",
    admin: [],
    category_id: "",
    category_index: "",
    founduser: {},
    ListOfTags: [],
    searchInput: "",
    selectedId: "",
    Category: "",
    totalPages: "",
    newtag: "",
    first_name: "",
    last_name: "",
    category: "",
    categoryList: [],
    currentPage: "",
    modalIsOpen: false,
    formError: "",
    heading: "",
    content: "",
    links: "",
    link: "",
    erit_team: [],
    thematic_areas: [],
    recipient: "",
    hascopiedLink: false,
    shouldUpdate: false,
    picture: "",
    pictures: [],
    photoname: "",
    attachments: "",
    modalIsOpenWarn: "",
    attachments1: "",
    attachments2: "",
    attachments3: "",
    attachments4: "",
    Loading: false,
    photos: [],
    payment_plans: [],
    Loading1: false,
  });
  const closeModal3 = () => {
    setState({
      ...state,
      modalIsOpen: false,
    });
  };
  const openModal3 = () => {
    setState({
      ...state,
      modalIsOpen: true,
    });
  };
  const openModalWarning = (id, index) => {
    setState({
      ...state,
      category_id: id,
      category_index: index,
      modalIsOpenWarn: true,
    });
  };
  const closeConfirmModal = () => {
    setState({
      ...state,
      modalIsOpenWarn: false,
    });
  };
  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get("page");
    setState({
      ...state,
      shouldUpdate: page ? true : false,
    });
    axios
      .all([
        axios.get(`${API}/admin/resource-detail/${page}/`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/admin/resource-list/`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/dashboard/profile`, {
          headers: { Authorization: `Token ${token}` },
        }),
        axios.get(`${API}/resource-categories/`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res1, res2, res3, res4) => {
          //console.log(res1);
          setState({
            ...state,
            ...res1.data,
            ...res4.data,
            erit_team: page ? res1.data.erit_team : [],
            thematic_areas: page ? res1.data.thematic_area : [],
            admin: res2.data,
            shouldUpdate: page ? true : false,
            first_name: res3.data.first_name,
            last_name: res3.data.last_name,
          });
          //console.log(res1);
          //console.log(res2);
        })
      )
      .catch(
        axios.spread((err, err1) => {
          //console.log(err1);
          setState({
            ...state,
            shouldUpdate: page ? true : false,
          });
          //console.log(err);
        })
      );
  }, []);

  //to fetch category list if the page is on resource creation conditon
  useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get("page");
    if (page) {
      return;
    }
    axios
      .all([
        axios.get(`${API}/resource-categories/`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res1) => {
          //console.log(res1.data.data);
          setState({
            ...state,
            categoryList: [...res1.data],
            payment_plans: [],
          });
          //console.log(res1);
        })
      )
      .catch(
        axios.spread((err, err1) => {
          //console.log(err1);
          setState({
            ...state,
            shouldUpdate: page ? true : false,
          });
          //console.log(err);
        })
      );
  }, []);
  const changeCopiedState = () => {
    setState({
      ...state,
      hascopiedLink: true,
    });
    setTimeout(() => {
      setState({
        ...state,
        hascopiedLink: false,
      });
    }, 3000);
  };
  const toggleDropdown = () => {
    setState({
      ...state,
      dropdwn: dropdwn ? false : true,
    });
  };
  const deleteCategory = () => {
    setState({
      ...state,
      Loading1: true,
    });
    const data = {
      category,
    };
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .delete(`${API}/admin/delete-resource-category/${state.category_id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        //console.log(res);
        const newarr = categoryList;
        const result = newarr.splice(state.category_index, 1);
        //console.log(result);
        setState({
          ...state,
          categoryList: newarr,
          Loading1: false,
          modalIsOpenWarn:false
        });
        notify("Category deleted")
      })
      .catch((err) => {
        notify("failed to delete");
        //console.log(err);
        setState({
          ...state,
          Loading1: false,
        });
      });
  };
  const addNewCat = () => {
    if (category.trim() == "") {
      return notify("Please enter category");
    }
    setState({
      ...state,
      Loading: true,
    });
    const data = {
      category,
    };
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .post(`${API}/admin/create-resource-category/`, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        //console.log(res);
        const newCat = [{ name: category, id: "" }];
        setState({
          ...state,
          categoryList: [...newCat, ...categoryList],
          category: "",
          Loading: false,
        });
      })
      .catch((err) => {
        notify("failed to add");
        //console.log(err);
        setState({
          ...state,
          Loading: false,
        });
      });
  };
  const onDrop = (picture) => {
    if (picture.length > 5) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return notify("Please select a maximum of 5 images");
    }
    const emptypictures: any = [];
    const result = picture.forEach((data: any, i) => {
      //console.log(data);
      return emptypictures.push(data);
    });
    const file = picture;
    //console.log(emptypictures);
    setState({
      ...state,
      pictures: emptypictures,
      attachments: emptypictures[0],
      attachments1: emptypictures[1] ? emptypictures[1] : "",
      attachments2: emptypictures[2] ? emptypictures[2] : "",
      attachments3: emptypictures[3] ? emptypictures[3] : "",
      attachments4: emptypictures[4] ? emptypictures[4] : "",
    });
  };
  const deleteSelected = (ind) => {
    let newarr = [pictures];
    //console.log(typeof newarr);
    const result = newarr.splice(ind, 1);
    //console.log(result);
    setState({
      ...state,
      pictures: result,
    });
  };
  const onCheckboxchange = (e) => {
    //console.log(e.target.checked);
    if (
      state[e.target.name].length >= 11 &&
      e.target.checked === true
      //&& e.target.name === "erit_team"
    ) {
      return;
    }
    if (e.target.checked) {
      setState({
        ...state,
        [e.target.name]: [...state[e.target.name], ...e.target.value],
      });
    }
    if (!e.target.checked) {
      const result: string[] = [];
      state[e.target.name]?.map((eachitem) => {
        //console.log(eachitem);
        if (e.target.value !== eachitem) {
          result.push(eachitem);
        }
      });
      setState({
        ...state,
        [e.target.name]: [...result],
      });
    }
  };
  const isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };
  const notify = (message: string) => toast(message, { containerId: "B" });
  const submitForm = () => {
    setState({
      ...state,
      isloading: true,
    });
    //console.log(thematic_areas);
    //console.log(erit_team);
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("page");
    const endpointIfPageIsTrue = !shouldUpdate
      ? `${API}/admin/create-resource/`
      : `${API}/admin/update-resource/${id}/`;
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    const data: any = new FormData();
    data.append("heading", heading);
    data.append("content", content);
    data.append("thematic_areas", JSON.stringify(thematic_areas));
    data.append("erit_team", JSON.stringify(erit_team));
    data.append("payment_plan", JSON.stringify(payment_plans));
    data.append("attachments", attachments);
    data.append("attachments1", attachments1);
    data.append("attachments2", attachments2);
    data.append("attachments3", attachments3);
    data.append("attachments4", attachments4);
    data.append("category", Category);
    data.append("link", link);
    const iterate: any = data.values(); //check the value of the formData
    for (var value of iterate) {
      //console.log(value);
    }
    axios
      .all([
        axios.post(`${endpointIfPageIsTrue}`, data, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res) => {
          setState({
            ...state,
            isloading: false,
          });
          notify("Successful");
          //console.log(res);
          setTimeout(() => {
            props.history.push("/resource_center");
          }, 3000);
        })
      )
      .catch((err) => {
        notify("Failed to process");
        //console.log(err.response);
        setState({
          ...state,
          isloading: false,
        });
      });
  };

  const {
    content,
    heading,
    attachments,
    attachments1,
    attachments2,
    attachments3,
    attachments4,
    formError,
    modalIsOpenWarn,
    thematic_areas,
    erit_team,
    Category,
    categoryList,
    modalIsOpen,
    pictures,
    shouldUpdate,
    photoname,
    payment_plans,
    links,
    hascopiedLink,
    dropdwn,
    category,
    isloading,
    recipient,
    link,
    Loading1,
    Loading,
    photos,
  } = state;
  //console.log(pictures);
  //console.log(erit_team);
  const eritTeam = ["Thought Leadership", "Project Execution"];
  const thematicAreas = [
    "School Leadership and Teacher Education",
    "Education Reform",
    "Educational Technology and Infrastructure",
    "Curriculum Review, Design and Development",
    "Media and Advocacy",
    "Research and Policy",
    "Education Funding & Innovation",
    "School Transformation (Primary)",
    "School Transformation (Secondary)",
  ];
  return (
    <Container fluid={true} className="contann122">
      <AdminNavBar overview={true} />
      <Row>
        <AdminSideBar resource={true} />
        <Col md={10} sm={12} className="prm bggray12">
          <DashboardLargeScreenNav title="Resource Center" />
          <Row className="">
            <Col md={12} className="kisls">
              <div className="kdashheader npps">
                <div className="fjss">
                  <div> </div>
                </div>
              </div>
              <div className="messageerit findbelow">
                <h6>
                  {" "}
                  {!shouldUpdate ? "Upload a new Resource" : "Update Resource"}
                </h6>
                <Link to="/resource_center">
                  <span className="clar23">&#8592; back</span>
                </Link>
              </div>
            </Col>
            <Col md={11} className="dmdar">
              <Row
                onClick={() => {
                  if (dropdwn) {
                    setState({
                      ...state,
                      dropdwn: false,
                    });
                  }
                }}
              >
                <Col md={12} className="">
                  <Form.Group>
                    <span
                      className={
                        formError && !heading
                          ? "userprofile12 formerror1"
                          : "userprofile12"
                      }
                    >
                      Add a Topic
                    </span>
                    <Form.Control
                      type="text"
                      onChange={onchange}
                      required
                      value={heading}
                      className={
                        formError && !heading ? "fmc12 formerror" : "fmc12"
                      }
                      name="heading"
                      placeholder=""
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="">
                  <Form.Group>
                    <span
                      className={
                        formError && !content
                          ? "userprofile12 formerror1"
                          : "userprofile12"
                      }
                    >
                      Describe the Resource
                    </span>
                    <div>
                      <textarea
                        className={
                          formError && !content
                            ? "fmc12 formerror area22"
                            : "fmc12 area22"
                        }
                        value={content}
                        name="content"
                        onChange={onchange}
                      />
                    </div>
                    {dropdwn && (
                      <div className="relddro">
                        <div className="dropdownwrap animated fadeIn">
                          <div className="tittle1 animated bounceInLeft">
                            ERIT Reform Team
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="erit_team"
                                checked={erit_team.indexOf("0") >= 0}
                                value={0}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp">
                              {" "}
                              Thought Leadership Team
                            </div>
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="erit_team"
                                checked={erit_team.indexOf("1") >= 0}
                                value={1}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp ">
                              Project Execution Team{" "}
                            </div>
                          </div>
                          <div className="divline12"></div>
                          <div className="tittle1 animated bounceInLeft">
                            Thematic Area
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="thematic_areas"
                                checked={thematic_areas.indexOf("0") >= 0}
                                value={0}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp">
                              {" "}
                              School Leadership and Teacher Education
                            </div>
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="thematic_areas"
                                checked={thematic_areas.indexOf("1") >= 0}
                                value={1}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp"> Education Reform</div>
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="thematic_areas"
                                checked={thematic_areas.indexOf("2") >= 0}
                                value={2}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp">
                              Educational Technology and Infrastructure
                            </div>
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="thematic_areas"
                                checked={thematic_areas.indexOf("3") >= 0}
                                value={3}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp">
                              Curriculum Review, Design and Development
                            </div>
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="thematic_areas"
                                checked={thematic_areas.indexOf("4") >= 0}
                                value={4}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp">Media and Advocacy</div>
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="thematic_areas"
                                checked={thematic_areas.indexOf("5") >= 0}
                                value={5}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp">Research and Policy</div>
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="thematic_areas"
                                checked={thematic_areas.indexOf("6") >= 0}
                                value={6}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp">
                              Education Funding & Innovation{" "}
                            </div>
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="thematic_areas"
                                checked={thematic_areas.indexOf("7") >= 0}
                                value={7}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp">
                              School Transformation (Primary){" "}
                            </div>
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="thematic_areas"
                                checked={thematic_areas.indexOf("8") >= 0}
                                value={8}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp">
                              School Transformation (Secondary)
                            </div>
                          </div>
                          <div className="divline12"></div>
                          <div className="tittle1 animated bounceInLeft">
                            Payment Plan
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="payment_plans"
                                checked={payment_plans.indexOf("0") >= 0}
                                value={0}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp">
                              Community membership plan{" "}
                            </div>
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="payment_plans"
                                checked={payment_plans.indexOf("1") >= 0}
                                value={1}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp">
                              Coporate membership plan{" "}
                            </div>
                          </div>
                          <div className="drrpr2 animated fadeInRight">
                            <label className="chckcontainer">
                              <input
                                type="checkbox"
                                onChange={onCheckboxchange}
                                name="payment_plans"
                                checked={payment_plans.indexOf("2") >= 0}
                                value={2}
                              />
                              <span className="chckcheckmark"></span>
                            </label>{" "}
                            <div className="ddrpp">
                              Professional membership plan{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="jdd1">
                <Col md={6}>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      onChange={onchange}
                      value={
                        erit_team.length > 0 || thematic_areas.length > 0
                          ? "Selected"
                          : ""
                      }
                      className={"fmca linkert linkertq"}
                      name="links"
                      placeholder=""
                    />
                    <div className="spna1a2">
                      <span className="spna122 flf"> Recipient</span>
                      <span className="spna122a" onClick={toggleDropdown}>
                        <span className="arrowa downa"></span>
                      </span>
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6} className="dggh">
                  <Form.Group>
                    <span
                      className={
                        formError && !link
                          ? "userprofile12 formerror1"
                          : "userprofile12"
                      }
                    >
                      Website url
                    </span>
                    <Form.Control
                      type="text"
                      onChange={onchange}
                      required
                      value={link}
                      className={
                        formError && !link ? "fmc12 formerror" : "fmc12"
                      }
                      name="link"
                      placeholder="eg  http://example.com"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="dggh">
                  <Form.Group>
                    <span
                      className={
                        formError && !category
                          ? "userprofile12 formerror1"
                          : "userprofile12"
                      }
                    >
                      Category
                    </span>
                    <Form.Control
                      as="select"
                      onChange={onchange}
                      required
                      className={
                        formError && !Category ? "fmc12 formerror" : "fmc12"
                      }
                      name="Category"
                      placeholder=""
                    >
                      <option></option>
                      {categoryList?.map((data, i) => (
                        <option key={i} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                    </Form.Control>
                    <div className="createcategory addnew">
                      <span
                        onClick={() => {
                          setState({
                            ...state,
                            modalIsOpen: true,
                          });
                        }}
                      >
                        Create category
                      </span>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row
                onClick={() => {
                  if (dropdwn) {
                    setState({
                      ...state,
                      dropdwn: false,
                    });
                  }
                }}
              >
                <Col md={12}>
                  <div className="moddd">Maximum of 5 attachments</div>
                  <ImageUploader
                    withIcon={false}
                    buttonText="Attach files"
                    onChange={onDrop}
                    withPreview={true}
                    imgExtension={[
                      ".jpg",
                      ".jpeg",
                      ".png",
                      ".gif",
                      ".png",
                      ".gif",
                      ".pdf",
                      ".xls",
                      ".xlsx",
                      ".xlsm",
                      ".xlsb",
                      ".txt",
                      ".xltx",
                      ".doc",
                      ".docx",
                      ".dotx",
                      ".tiff",
                      ".psd",
                      ".wav",
                      ".zip",
                      "ppt",
                    ]}
                    label={`Maximum acceptable file size 10mb. Supported file extensions .jpg,
                    .jpeg,
                    .gif,
                    .png,
                    .png",
                    .gif,
                    .pdf,
                    .xls,
                    .txt,
                    .xlsm,
                    .doc,
                    .xlsb,.xltx`}
                    withLabel={true}
                    maxFileSize={10242880}
                    // singleImage={true}
                    accept="*"
                  />
                </Col>
                <Col md={12}>
                  {pictures[0] && (
                    <div className="Uploads1">Selected file title</div>
                  )}
                  <div className="addz">
                    {pictures.map((data, i) => (
                      <span className="addz23" key={i}>
                        {data.name}{" "}
                        {/* <span className="xxs" onClick={() => deleteSelected(i)}>
                          x
                        </span> */}
                      </span>
                    ))}
                    {photoname && (
                      <span className="addz23">{photoname.name} </span>
                    )}
                  </div>
                  <div className="fix3images">
                    {photos?.map((data, i) => (
                      <div className="eritimg1" key={i}>
                        <img className="professional4" src={data.url} />
                        <div className="tht12"></div>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="text-center odd132">
                    {!shouldUpdate && (
                      <Button className="winebtn" onClick={submitForm}>
                        {isloading ? "Posting..." : "Post this Resource"}
                      </Button>
                    )}
                    {shouldUpdate && (
                      <Button className="winebtn" onClick={submitForm}>
                        {isloading ? "Updating..." : "Update"}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        show={modalIsOpenWarn}
        centered={true}
        className=""
        onHide={closeConfirmModal}
      >
        <div className="exittxt">
          Are you sure you want to{" "}
          <span className="exittxtsub">delete this category?</span>
        </div>
        <div className="exittxt">
          This action would delete all the resources under this category{" "}
        </div>
        <div className="exitbtns">
          <button className="exitbtn1" onClick={closeConfirmModal}>
            Go back
          </button>
          <button className="exitbtn2" onClick={deleteCategory}>
            {!Loading1 ? "Delete" : "Processing"}
          </button>
        </div>
        <Modal.Body className="profl"></Modal.Body>
      </Modal>
      <Modal
        show={modalIsOpen}
        centered={true}
        className=""
        onHide={closeModal3}
      >
        <div className="text-right">
          <span onClick={closeModal3} className="closeicone">&times;</span>
        </div>
        <Modal.Body className="profl">
          <Col md={12} className="nndx">
            <Row
              onClick={() => {
                if (dropdwn) {
                  setState({
                    ...state,
                    dropdwn: false,
                  });
                }
              }}
            >
              <Col md={12}>
                <h6> Create Resource category</h6>
              </Col>
              <Col md={12} className="">
                <Form.Group>
                  <span
                    className={
                      formError && !heading
                        ? "userprofile12 formerror1"
                        : "userprofile12"
                    }
                  >
                    Category
                  </span>
                  <Form.Control
                    type="text"
                    onChange={onchange}
                    required
                    value={category}
                    className={"fmc12"}
                    name="category"
                    placeholder="Add a new category"
                    onKeyPress={(e) => {
                      if (e.key == "Enter") {
                        addNewCat();
                      }
                    }}
                  />
                </Form.Group>
                <div className="text-right">
                  <span className="addnew" onClick={addNewCat}>
                    {!Loading ? "Add" : "Adding..."}
                  </span>
                </div>
              </Col>
              <Col md={12}>
                <div className="flexxend">
                  {!Loading1 ? "" : <Spinner animation={"grow"} />}
                  {categoryList.map((data, i) => (
                    <div key={i} className="categoryaa animated fadeInRight">
                      {data.name}
                      <span
                        className="close23"
                        onClick={() => openModalWarning(data.id, i)}
                      >
                        &times;
                      </span>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>{" "}
      <ToastContainer
        enableMultiContainer
        containerId={"h"}
        toastClassName="bg-info text-white"
        hideProgressBar={true}
        position={toast.POSITION.TOP_CENTER}
      />
    </Container>
  );
});

export default AdminCreateResourceCenter;
