import React from "react";
import { Col, Container } from "react-bootstrap";
import "./pricing.css";
import NavBar from "../HomeComponents/newnavbar";
import Footer from "../HomeComponents/newfooter";
import Pricing from "./pricingsummary";

const C4CPricing = () => {
  return (
    <div>
      <NavBar pricing={true} />
      <Pricing />
      <Footer />
    </div>
  );
};

export default C4CPricing;
