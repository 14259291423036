import * as React from "react";
import demoLogo from "../../../assets/Claritylogo.png";
import demoLogo1 from "../../../assets/clarity1.png";
import SideNav from "react-simple-sidenav";
import { Link, Redirect } from "react-router-dom";
import "../Home/animate.css";
import { NavIsLoggedOut } from "./isloggedout";
import { NavIsLoggedIn } from "./isloggedIn";
import { useEffect } from "react";
import Axios, { AxiosResponse } from "axios";
import { API } from "../../../config";
import sideimage from "../../../assets/jn.png";
import overview from "../../../assets/overview.png";
import settings from "../../../assets/settings.png";
import profilebuilder from "../../../assets/profilebuilder.png";
import support from "../../../assets/support.png";
import "../Home/Home.css";

const Navbar: React.FC = (props: any) => {
  const [state, setShowNav] = React.useState<any>({
    showNav: false,
    userLoggedIn: false,
    redirect: false,
  });
  const [state1, setState] = React.useState({
    canseeDashboard: "",
  });
  const { showNav, userLoggedIn, redirect } = state;
  useEffect(() => {
    window.scrollTo(-0, -0);
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken ? JSON.parse(availableToken) : "";
    const availableuser: any = localStorage.getItem("user");
    const user = availableToken ? JSON.parse(availableuser) : "";
    // if (user[0]?.user_type == "ERITUSER") {
    //   setState({
    //     ...state1,
    //     canseeDashboard: "user",
    //   });
    // }
    if (token) {
      if (
        window.location.pathname === "/assessmentphaseone" ||
        window.location.pathname === "/assessmentphasetwo" || 
        window.location.pathname === "/assessmentphasethree" ||
        window.location.pathname === "/assessmentphasefour" ||
        window.location.pathname === "/assessmentphasefour1" ||
        window.location.pathname === "/assessmentphasefive" ||
        window.location.pathname === "/assessmentphasesix" ||
        window.location.pathname === "/assessmentphaseseven" ||
        window.location.pathname === "/assessmentphasesevencomplete" ||
        window.location.pathname === "/assessmentphasesixcomplete" ||
        window.location.pathname === "/assessmentphasefivecomplete" ||
        window.location.pathname === "/assessmentphasefourcomplete" ||
        window.location.pathname === "/thirdphasecomplete" ||
        window.location.pathname === "/secondphasecomplete" ||
        window.location.pathname === "/assessmentphasecomplete"
      ) {
        setShowNav({ ...state, userLoggedIn: true });
      } else {
        setShowNav({ ...state, userLoggedIn: true });
        window.location.assign("/overview");
      }
    } else {
      setShowNav({ ...state, userLoggedIn: false });
    }
  }, []);
  const setRedirect = () => {
    setShowNav({
      ...state,
      redirect: true,
    });
  };
  const renderRedirect = () => {
    if (redirect && window.location.pathname !== "/") {
      return <Redirect to="/" />;
    }
    if (redirect && window.location.pathname == "/") {
      window.location.reload();
    }
  };

  const logout = () => {
    localStorage.clear();
    setRedirect();
  };
  const login = () => {
    if (
      window.location.pathname === "/signup" ||
      window.location.pathname === "/signin"
    ) {
      window.location.assign("/signin");
    } else if (
      window.location.pathname === "/signup" ||
      window.location.pathname === "/signup"
    ) {
      window.location.assign("/signin");
    } else {
      window.location.assign("/signin");
    }
  };
  const uniqueKeygen = (): number => {
    return Math.floor(Math.random() * 100);
  };
  //console.log(state1.canseeDashboard);
  return (
    <div>
      {renderRedirect()}
      {/* mobile ends */}
      <div className="Navsection ">
        <div className="top-layer">
          {/* mobile */}
          <div className="lakk">
            <SideNav
              style={{ background: showNav ? "rgba(0, 0, 0, 0.7)" : "inherit" }}
              navStyle={{ width: "70%", background: "#131313" }}
              showNav={showNav}
              onHideNav={() => setShowNav({ ...state, showNav: true })}
              titleStyle={{
                backgroundColor: "#9C125F",
                color: "#444444",
                paddingLeft: 10,
                paddingBottom: 0,
                paddingTop: 0,
                fontSize: 17,
                textAlign: "left",
              }}
              itemStyle={{ backgroundColor: "#131313", paddingLeft: 25 }}
              itemHoverStyle={{ backgroundColor: "inherit" }}
              title={[
                <div
                  key={uniqueKeygen()}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    background: "#9C125F",
                    padding: "0px 4px 1px 8px",
                    color: "white",
                    fontSize: "4rem",
                  }}
                >
                  <span
                    className={
                      showNav ? "tymes animated lightSpeedIn" : "tymes"
                    }
                    onClick={() =>
                      setShowNav({ ...state, showNav: !showNav ? true : false })
                    }
                  >
                    &times;
                  </span>
                </div>,
              ]}
              items={[
                <div
                  className={
                    showNav ? "listwraper animated fadeInLeft" : "listwraper"
                  }
                >
                  {!userLoggedIn && (
                    <>
                      <div className="listwraperMob">
                        <div className="navmobbtn" onClick={login}>
                          Login
                        </div>
                      </div>
                      <div className="listwraperMob">
                        <Link to="/signup">
                          <div className="navmobbtn">Sign Up</div>
                        </Link>
                      </div>
                    </>
                  )}
                  {userLoggedIn && (
                    <>
                      {/* <div className="dlex">
                      </div>{" "}
                      <div className={"navitem1 ptod"}>
                        <div className={props.overview ? "activegb" : "gbn"}>
                          <Link to="/overview">
                            <img
                              src={overview}
                              className="sideimage"
                              alt="sideimage"
                            />{" "}
                            Overview
                          </Link>
                        </div>
                        <div
                          onClick={checkIfUserHasMadePaymentForFullResult}
                          className={props.insight ? "activegb " : " activegb gbn"}
                        >
                          {" "}
                          <img
                            src={sideimage}
                            className="sideimage"
                            alt="sideimage"
                          />
                          Career Insight
                        </div>
                        <div className="divide_thro"></div>
                        <div className={props.settings ? "activegb" : "gbn"}>
                          {" "}
                          <Link to="/dashboardsettings">
                            <img
                              src={settings}
                              className="sideimage"
                              alt="sideimage"
                            />
                            Settings
                          </Link>
                        </div>
                        <div className={props.support ? "activegb" : "gbn"}>
                          {" "}
                          <div onClick={logout}>
                            <img
                              src={support}
                              className="sideimage"
                              alt="sideimage"
                            />
                             Dashboard
                          </div>
                        </div>
                      </div> */}
                      <div className={props.overview ? "activegb" : "gbn"}>
                        <Link className="goodlink" to="/">
                          Home
                        </Link>
                      </div>
                      <div className={props.support ? "activegb" : "gbn"}>
                        {" "}
                        <div className="goodlink" onClick={logout}>
                          Logout
                        </div>
                      </div>

                      {state1.canseeDashboard === "user" && (
                        <div className={props.overview ? "activegb" : "gbn"}>
                          <Link className="goodlink" to="/overview">
                            Dashboard
                          </Link>
                        </div>
                      )}
                      {state1.canseeDashboard === "admin" && (
                        <div className={props.overview ? "activegb" : "gbn"}>
                          <Link className="goodlink" to="/adminoverview">
                            Dashboard
                          </Link>
                        </div>
                      )}
                    </>
                  )}
                </div>,
              ]}
            />
            <div className="top-layermobile"></div>
            <div className="flexsss">
              <Link to="/">
                <img
                  src={demoLogo}
                  className="clarity_logo saedlogo"
                  alt="clarity_logo"
                />
              </Link>
              <div className="hamburgerwrap">
                <div
                  className="hamburger"
                  onClick={() =>
                    setShowNav({ ...state, showNav: !showNav ? true : false })
                  }
                >
                  <div className="line1"></div>
                  <div className="line2"></div>
                  <div className="line3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-wrapper">
          <div className="nav_title">
            <div className="logo_clarity">
              <Link to="/">
                <img src={demoLogo} className="saedlogo" alt="clarity_logo" />
              </Link>
            </div>
          </div>
          <div className="nav_title">
            {!userLoggedIn ? (
              <NavIsLoggedOut />
            ) : (
              <NavIsLoggedIn Logout={logout} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
