import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import "./style.css";
import onboarding1 from "../../assets/onboarding1.svg";
import onboarding2 from "../../assets/onboarding2.svg";
import onboarding3 from "../../assets/onboarding3.svg";
import onboarding4 from "../../assets/onboarding4.svg";
import Axios, { AxiosResponse } from "axios";
import { API } from "../../config";
import { toast } from "react-toastify";

const IndividualOnboardingModals = (props: any) => {
  const [state, setState] = useState({
    isOpen: 1,
    shouldFillProfilesettings: true,
    shouldFillCvBuilder: false,
    errorMessage: "",
    isLoading: false,
    first_name: "",
    last_name: "",
  });

  const open1 = () => {
    setState({
      ...state,
      isOpen: 1,
    });
  };
  const open2 = () => {
    setState({
      ...state,
      isOpen: 2,
    });
  };
  const open3 = () => {
    setState({
      ...state,
      isOpen: 3,
    });
  };
  const open4 = () => {
    setState({
      ...state,
      isOpen: 4,
    });
  };

  const { first_name, last_name, isOpen } = state;
  React.useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken ? JSON.parse(availableToken) : "";
    const data = {};
    Axios.get<any, AxiosResponse<any>>(`${API}/dashboard/profile`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          setState({
            ...state,
            ...response.data,
          });
        }
      })
      .catch((error) => {
        //console.log(error.response);
        if (error && error.response && error.response.data) {
          setState({
            ...state,
            errorMessage: error.response.data[0].message,
            isLoading: false,
          });
        }
        setState({
          ...state,
          errorMessage: "failed",
          isLoading: false,
        });
      });
  }, []);
  const finishOnboarding = () => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken ? JSON.parse(availableToken) : "";
    Axios.get<any, AxiosResponse<any>>(`${API}/onboarding`, {
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          // notify("Successfully added to team");
          setTimeout(() => {
          window.location.assign("/overview");
          }, 2000);
        }
      })
      .catch((error) => {
        //console.log(error.response);
      });
  };
  const notify = (message: string) => toast(message, { containerId: "i" });
  return (
    <div className="onbpadding">
      {isOpen === 1 && (
        <div>
          <div className="text-center"> </div>
          <div className="skip" onClick={finishOnboarding}>
            Skip
          </div>
          <div className="welcome">
            {" "}
            Welcome{" "}
            <span className="user_name">
              {" "}
              {first_name} {last_name}
            </span>{" "}
            to the C4C Portal
          </div>
          <div className="intro">
            To get you started with building your profile,
            let’s take you through the following simple steps . . .
          </div>
          <div className="image">
            <img className="build_img" src={onboarding1} alt="start building" />
          </div>
          <div className="build">Build your Professional Profile</div>
          <div className="descrip">
            You need to provide more details about you and your interests, to
            enable us understand you better
          </div>
          <div className="scroll">
            <span className="rect"> </span>
            <span className="circle-onboarding"> </span>
            <span className="circle-onboarding"> </span>
            <span className="circle-onboarding"> </span>
            <span className="text-center">
              <div className="next" onClick={open2}>
                Next
              </div>
            </span>
          </div>
        </div>
      )}
      {isOpen === 2 && (
        <div>
          <div className="text-center"> </div>
          <div className="skip" onClick={finishOnboarding}>
            Skip
          </div>
          <div className="welcome">
            {" "}
            Welcome{" "}
            <span className="user_name">
              {" "}
              {first_name} {last_name}
            </span>{" "}
            to the C4C Portal
          </div>
          <div className="intro">
            Clarity for Communities is powered by a Productivity Culture that priortizes actions over words
            
          </div>
          <div className="image">
            <img className="onbimg2" src={onboarding2} alt="start building" />
          </div>
          <div className="build">Complete a Personal Competency Assessment</div>
          <div className="descrip">
            To help improve your experience on the platform, you will be required to complete a
            competency assessment
          </div>
          <div className="scroll">
            <span className="circle-onboarding"> </span>
            <span className="rect"> </span>
            <span className="circle-onboarding"> </span>
            <span className="circle-onboarding"> </span>
            <span className="text-center">
              <div className="next" onClick={open3}>
                Next
              </div>
              <div className="prev" onClick={open1}>
                Prev
              </div>
            </span>
          </div>
        </div>
      )}
      {isOpen === 3 && (
        <div>
          <div className="text-center"> </div>
          <div className="skip" onClick={finishOnboarding}>
            Skip
          </div>
          <div className="welcome">
            {" "}
            Welcome{" "}
            <span className="user_name">
              {first_name} {last_name}
            </span>{" "}
            to the C4C Portal
          </div>
          <div className="intro">
              Clarity for Communities focuses on life long learning
          </div>
          <div className="image">
            <img className="onbimg3" src={onboarding3} alt="start building" />
          </div>
          <div className="build">Build Capacity</div>
          <div className="descrip">
            Clarity for Communities' Resource Centre provides tailored learning / development resources and opportunities
          </div>
          <div className="scroll">
            <span className="circle-onboarding"> </span>
            <span className="circle-onboarding"> </span>
            <span className="rect"> </span>
            <span className="circle-onboarding"> </span>
            <span className="text-center">
              <div className="next" onClick={open4}>
                Next
              </div>
              <div className="prev" onClick={open2}>
                Prev
              </div>
            </span>
          </div>
        </div>
      )}
      {isOpen === 4 && (
        <div>
          <div className="text-center"> </div>
          <div className="skip" onClick={finishOnboarding}>
            Skip
          </div>
          <div className="welcome">
            {" "}
            Welcome{" "}
            <span className="user_name">
              {first_name} {last_name}
            </span>{" "}
            to the C4C Portal
          </div>
          <div className="intro">
            This concludes Member Onboarding Process.
          </div>
          <div className="image">
            <img className="build_img buttmarg" src={onboarding4} alt="start building" />
          </div>
          {/* <div className="build">Send a message of completion</div>
          <div className="descrip">
            Quickly use our messaging feature to drop us a message after
            completing your profile, and be ready to get paired to great
            opportunities
          </div> */}
          <div className="scroll">
            <span className="circle-onboarding"> </span>
            <span className="circle-onboarding"> </span>
            <span className="circle-onboarding"> </span>
            <span className="rect"> </span>
            <span className="text-center">
              <div className="next" onClick={finishOnboarding}>
                Finish
              </div>
              <div className="prev" onClick={open3}>
                Prev
              </div>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default IndividualOnboardingModals;
