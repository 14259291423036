import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../kegennidashboard.css";
import "./../dashboard.css";
import DashboardLargeScreenNav from "../DashboardLargeScreenNav";
import AdminSideBar from "./AdminSideBar";
import AdminNavBar from "./AdminNavBar";
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../config";
import cart from "../../../assets/emptycart.svg";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pricing from "../../Home/Pricing/pricingsummary"

const AdminAllSubscriptions = () => {
  const [state, setState] = useState<any>({
    last_name: "",
    first_name: "",
  });
  const { last_name, first_name } = state;
  useEffect(() => {
    const availableToken = localStorage.getItem("userToken");
    const token = availableToken
      ? JSON.parse(availableToken)
      : window.location.assign("/signin");
    axios
      .all([
        axios.get(`${API}/dashboard/profile`, {
          headers: { Authorization: `Token ${token}` },
        }),
      ])
      .then(
        axios.spread((res1) => {
          setState({
            ...state,
            first_name: res1.data.first_name,
            last_name: res1.data.last_name,
          });
          //console.log(res1);
        })
      )
      .catch((err) => {
        //console.log(err);
      });
  }, []);
  return (
    <>
      <Container fluid={true} className="contann122">
        <AdminNavBar overview={true} />
        <Row>
          <AdminSideBar overview={true} />
          <Col md={10} sm={12} className="prm newprm">
            <DashboardLargeScreenNav title="Subscription" />
            <Row>
              <Col md={12} className="kisls">
                <div className="kdashheader npps">
                  <div className="fjss">
                    <div>
                      {" "}
                      <span className="kdashheaderlight idds">
                        Hi{" "}
                        <span className="ksname">
                          {first_name ? first_name + " " + last_name : ""}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="messageerit findbelow">
                  A review of your subscription plan
                </div>
                <div className="">
                  <Button className="retaketest">
                    <Link to="/admin/subscription">
                      Go Back
                    </Link>
                  </Button>
                </div>
                <div>
                  <hr />
                </div>
                <Pricing />
                
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminAllSubscriptions;
